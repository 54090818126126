import {
  TFdrFlatDestination,
  TPossibleSearchItems,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { getPageById } from '@hotelplan/libs.context.object-id';

const CONTINENT_SHORTCUT = 'k';
const COUNTRY_GROUP_SHORTCUT = 'g';
const COUNTRY_SHORTCUT = 'c';
const REGION_SHORTCUT = 'r';
const DESTINATION_SHORTCUT = 'd';
const RESORT_SHORTCUT = 'f';

const destinationTypenameMap = new Map<string, TPossibleSearchItems>([
  [CONTINENT_SHORTCUT, `FdrContinent`],
  [COUNTRY_GROUP_SHORTCUT, `FdrCountryGroup`],
  [COUNTRY_SHORTCUT, `FdrCountry`],
  [REGION_SHORTCUT, `FdrRegion`],
  [DESTINATION_SHORTCUT, `FdrDestination`],
  [RESORT_SHORTCUT, `FdrResort`],
]);

export const mapGeoObjectIdAndNameToTravelDestination = (
  id: string,
  name: string
) => {
  const page = getPageById(id);

  if (page?.objectType && destinationTypenameMap.has(page.objectType)) {
    return {
      id,
      name,
      canonicalName: name,
      __typename: destinationTypenameMap.get(page.objectType),
    } as TFdrFlatDestination;
  }

  return null;
};
