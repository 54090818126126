import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import FdrSearchHistorySkeleton from './fdr-search-history.skeleton';
import {
  FdrSearchHistoryLoadMoreButton,
  SearchHistoryGroupWrapper,
  SearchHistoryLinkButtonWrapper,
} from './fdr-search-history.styles';

export interface ISearchHistoryGroupProps {
  header?: string;
  noItemsMessage?: string;
  testId?: string;
  onNextPage?(): void;
  loading?: boolean;
}

const FdrSearchHistoryGroup: React.FC<
  PropsWithChildren<ISearchHistoryGroupProps>
> = ({ header, noItemsMessage, testId, onNextPage, children, loading }) => {
  const [t] = useTranslation(['common', 'search']);

  return (
    <SearchHistoryGroupWrapper className="historyGroup" data-id={testId}>
      {header && <h3 className="search-history-group-title">{header}</h3>}
      {React.Children.count(children) > 0 ? children : noItemsMessage}
      <SearchHistoryLinkButtonWrapper>
        {loading ? <FdrSearchHistorySkeleton /> : null}
        {onNextPage ? (
          <FdrSearchHistoryLoadMoreButton
            variant={E_BUTTON_TYPE.LINK_BUTTON}
            className="search-history-button"
            data-id="load-more-button"
            onClick={onNextPage}
            disabled={loading}
          >
            {t('common:load.more')}
            <Icon name="chevron-down" />
          </FdrSearchHistoryLoadMoreButton>
        ) : null}
      </SearchHistoryLinkButtonWrapper>
    </SearchHistoryGroupWrapper>
  );
};

export default FdrSearchHistoryGroup;
