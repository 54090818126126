import React, { useEffect } from 'react';
import { useFdrGeoNameQuery } from 'fdr/schemas/query/geo/fdr-geo-name.generated';

export const FdrTopDestinationGeoNameQueryComponent: React.FC<{
  id: string;
  onLoad(id: string, name: string | null): void;
}> = ({ id, onLoad }) => {
  const { data, loading, error } = useFdrGeoNameQuery({ variables: { id } });

  useEffect(() => {
    if (!loading && data?.fdrGeo?.name?.length) {
      onLoad(id, data?.fdrGeo?.name);
    } else if (!loading && (error || !data?.fdrGeo?.name?.length)) {
      onLoad(id, null);
    }
  }, [loading, data, error]);

  return null;
};
