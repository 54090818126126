import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { DescriptionGeoLinkFragmentDoc } from 'fdr/schemas/query/pdp/overview/fdr-product-overview.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrTextSearchAutocompleteQueryVariables = Types.Exact<{
  text: Types.Scalars['String'];
  page?: Types.Scalars['Int'];
  searchType: Array<Types.FdrSearchType> | Types.FdrSearchType;
  selected?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
}>;

export type FdrTextSearchAutocompleteQuery = {
  __typename?: 'Query';
  fdrTextAutocomplete: {
    __typename?: 'FdrTextAutocompleteResponse';
    pagination: {
      __typename?: 'FdrPagination';
      page: number;
      total: number;
      perPage: number;
    };
    itemList?: Array<
      | {
          __typename?: 'FdrAdventureTravelSearchItem';
          adventureTravel: {
            __typename?: 'FdrAdventureTravel';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | { __typename?: 'FdrAgencyOverviewPageSearchItem' }
      | {
          __typename?: 'FdrAirportSearchItem';
          airport: {
            __typename?: 'FdrAirport';
            iata: string;
            isGroup: boolean;
            codes?: Array<string> | null;
            name: string;
            id: string;
          };
        }
      | { __typename?: 'FdrBlogArticleSearchItem' }
      | { __typename?: 'FdrCatalogPageSearchItem' }
      | {
          __typename?: 'FdrContinentSearchItem';
          continent: {
            __typename?: 'FdrContinent';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCountryGroupSearchItem';
          countryGroup: {
            __typename?: 'FdrCountryGroup';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCountrySearchItem';
          country: {
            __typename?: 'FdrCountry';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrCruiseSearchItem';
          cruise: {
            __typename?: 'FdrCruise';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrDestinationSearchItem';
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string };
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrHotelSearchItem';
          hotel: {
            __typename?: 'FdrHotel';
            name: string;
            id: string;
            resort: {
              __typename?: 'FdrResort';
              name: string;
              destination: {
                __typename?: 'FdrDestination';
                name: string;
                country: { __typename?: 'FdrCountry'; name: string };
              };
            };
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | { __typename?: 'FdrNewsletterSubscriptionPageSearchItem' }
      | {
          __typename?: 'FdrRegionSearchItem';
          region: {
            __typename?: 'FdrRegion';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string };
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrResortSearchItem';
          resort: {
            __typename?: 'FdrResort';
            name: string;
            id: string;
            destination: {
              __typename?: 'FdrDestination';
              name: string;
              country: { __typename?: 'FdrCountry'; name: string };
            };
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | {
          __typename?: 'FdrRoundTripSearchItem';
          roundTrip: {
            __typename?: 'FdrRoundTrip';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrProductWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
      | { __typename?: 'FdrStaticPageSearchItem' }
      | {
          __typename?: 'FdrThemeSearchItem';
          theme: {
            __typename?: 'FdrTheme';
            name: string;
            id: string;
            webMeta: {
              __typename?: 'FdrThemeWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          };
        }
    > | null;
  };
};

export type FdrAutocompleteItemFdrAdventureTravelSearchItemFragment = {
  __typename?: 'FdrAdventureTravelSearchItem';
  adventureTravel: {
    __typename?: 'FdrAdventureTravel';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrAgencyOverviewPageSearchItemFragment = {
  __typename?: 'FdrAgencyOverviewPageSearchItem';
};

export type FdrAutocompleteItemFdrAirportSearchItemFragment = {
  __typename?: 'FdrAirportSearchItem';
  airport: {
    __typename?: 'FdrAirport';
    iata: string;
    isGroup: boolean;
    codes?: Array<string> | null;
    name: string;
    id: string;
  };
};

export type FdrAutocompleteItemFdrBlogArticleSearchItemFragment = {
  __typename?: 'FdrBlogArticleSearchItem';
};

export type FdrAutocompleteItemFdrCatalogPageSearchItemFragment = {
  __typename?: 'FdrCatalogPageSearchItem';
};

export type FdrAutocompleteItemFdrContinentSearchItemFragment = {
  __typename?: 'FdrContinentSearchItem';
  continent: {
    __typename?: 'FdrContinent';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrCountryGroupSearchItemFragment = {
  __typename?: 'FdrCountryGroupSearchItem';
  countryGroup: {
    __typename?: 'FdrCountryGroup';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrCountrySearchItemFragment = {
  __typename?: 'FdrCountrySearchItem';
  country: {
    __typename?: 'FdrCountry';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrCruiseSearchItemFragment = {
  __typename?: 'FdrCruiseSearchItem';
  cruise: {
    __typename?: 'FdrCruise';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrDestinationSearchItemFragment = {
  __typename?: 'FdrDestinationSearchItem';
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string };
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrHotelSearchItemFragment = {
  __typename?: 'FdrHotelSearchItem';
  hotel: {
    __typename?: 'FdrHotel';
    name: string;
    id: string;
    resort: {
      __typename?: 'FdrResort';
      name: string;
      destination: {
        __typename?: 'FdrDestination';
        name: string;
        country: { __typename?: 'FdrCountry'; name: string };
      };
    };
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrNewsletterSubscriptionPageSearchItemFragment =
  { __typename?: 'FdrNewsletterSubscriptionPageSearchItem' };

export type FdrAutocompleteItemFdrRegionSearchItemFragment = {
  __typename?: 'FdrRegionSearchItem';
  region: {
    __typename?: 'FdrRegion';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string };
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrResortSearchItemFragment = {
  __typename?: 'FdrResortSearchItem';
  resort: {
    __typename?: 'FdrResort';
    name: string;
    id: string;
    destination: {
      __typename?: 'FdrDestination';
      name: string;
      country: { __typename?: 'FdrCountry'; name: string };
    };
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrRoundTripSearchItemFragment = {
  __typename?: 'FdrRoundTripSearchItem';
  roundTrip: {
    __typename?: 'FdrRoundTrip';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFdrStaticPageSearchItemFragment = {
  __typename?: 'FdrStaticPageSearchItem';
};

export type FdrAutocompleteItemFdrThemeSearchItemFragment = {
  __typename?: 'FdrThemeSearchItem';
  theme: {
    __typename?: 'FdrTheme';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrThemeWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteItemFragment =
  | FdrAutocompleteItemFdrAdventureTravelSearchItemFragment
  | FdrAutocompleteItemFdrAgencyOverviewPageSearchItemFragment
  | FdrAutocompleteItemFdrAirportSearchItemFragment
  | FdrAutocompleteItemFdrBlogArticleSearchItemFragment
  | FdrAutocompleteItemFdrCatalogPageSearchItemFragment
  | FdrAutocompleteItemFdrContinentSearchItemFragment
  | FdrAutocompleteItemFdrCountryGroupSearchItemFragment
  | FdrAutocompleteItemFdrCountrySearchItemFragment
  | FdrAutocompleteItemFdrCruiseSearchItemFragment
  | FdrAutocompleteItemFdrDestinationSearchItemFragment
  | FdrAutocompleteItemFdrHotelSearchItemFragment
  | FdrAutocompleteItemFdrNewsletterSubscriptionPageSearchItemFragment
  | FdrAutocompleteItemFdrRegionSearchItemFragment
  | FdrAutocompleteItemFdrResortSearchItemFragment
  | FdrAutocompleteItemFdrRoundTripSearchItemFragment
  | FdrAutocompleteItemFdrStaticPageSearchItemFragment
  | FdrAutocompleteItemFdrThemeSearchItemFragment;

export type FdrAutocompleteAirportSearchItemFragment = {
  __typename?: 'FdrAirportSearchItem';
  airport: {
    __typename?: 'FdrAirport';
    iata: string;
    isGroup: boolean;
    codes?: Array<string> | null;
    name: string;
    id: string;
  };
};

export type FdrAutocompleteCruiseSearchItemFragment = {
  __typename?: 'FdrCruiseSearchItem';
  cruise: {
    __typename?: 'FdrCruise';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteDestinationSearchItemFragment = {
  __typename?: 'FdrDestinationSearchItem';
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string };
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteResortSearchItemFragment = {
  __typename?: 'FdrResortSearchItem';
  resort: {
    __typename?: 'FdrResort';
    name: string;
    id: string;
    destination: {
      __typename?: 'FdrDestination';
      name: string;
      country: { __typename?: 'FdrCountry'; name: string };
    };
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteAtSearchItemFragment = {
  __typename?: 'FdrAdventureTravelSearchItem';
  adventureTravel: {
    __typename?: 'FdrAdventureTravel';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteHotelSearchItemFragment = {
  __typename?: 'FdrHotelSearchItem';
  hotel: {
    __typename?: 'FdrHotel';
    name: string;
    id: string;
    resort: {
      __typename?: 'FdrResort';
      name: string;
      destination: {
        __typename?: 'FdrDestination';
        name: string;
        country: { __typename?: 'FdrCountry'; name: string };
      };
    };
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteRoundTripSearchItemFragment = {
  __typename?: 'FdrRoundTripSearchItem';
  roundTrip: {
    __typename?: 'FdrRoundTrip';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrProductWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteContinentSearchItemFragment = {
  __typename?: 'FdrContinentSearchItem';
  continent: {
    __typename?: 'FdrContinent';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteCountryGroupSearchItemFragment = {
  __typename?: 'FdrCountryGroupSearchItem';
  countryGroup: {
    __typename?: 'FdrCountryGroup';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteCountrySearchItemFragment = {
  __typename?: 'FdrCountrySearchItem';
  country: {
    __typename?: 'FdrCountry';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteRegionSearchItemFragment = {
  __typename?: 'FdrRegionSearchItem';
  region: {
    __typename?: 'FdrRegion';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string };
    webMeta: {
      __typename?: 'FdrGeoWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrAutocompleteThemeSearchItemFragment = {
  __typename?: 'FdrThemeSearchItem';
  theme: {
    __typename?: 'FdrTheme';
    name: string;
    id: string;
    webMeta: {
      __typename?: 'FdrThemeWebMeta';
      descriptionWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  };
};

export type FdrProductMetaLinkFragment = {
  __typename?: 'FdrProductWebMeta';
  descriptionWebMeta?: {
    __typename?: 'FdrMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
};

export const FdrProductMetaLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductMetaLink' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductWebMeta' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptionWebMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteAtSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteATSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAdventureTravelSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adventureTravel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductMetaLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductMetaLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteHotelSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteHotelSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHotelSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resort' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductMetaLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductMetaLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteRoundTripSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteRoundTripSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRoundTripSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roundTrip' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductMetaLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductMetaLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteContinentSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteContinentSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrContinentSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'continent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteCountryGroupSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteCountryGroupSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCountryGroupSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteCountrySearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteCountrySearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCountrySearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteRegionSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteRegionSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRegionSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'region' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteThemeSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteThemeSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrThemeSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'descriptionWebMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrLink' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteAirportSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteAirportSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAirportSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'airport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'iata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGroup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteCruiseSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteCruiseSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCruiseSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cruise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrProductMetaLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductMetaLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteDestinationSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteDestinationSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrDestinationSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteResortSearchItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteResortSearchItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrResortSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'descriptionGeoLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...DescriptionGeoLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrAutocompleteItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAutocompleteItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteATSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteHotelSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteRoundTripSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteContinentSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'fdrAutocompleteCountryGroupSearchItem',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteCountrySearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteRegionSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteThemeSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteAirportSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteCruiseSearchItem' },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'fdrAutocompleteDestinationSearchItem',
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAutocompleteResortSearchItem' },
          },
        ],
      },
    },
    ...FdrAutocompleteAtSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteHotelSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteRoundTripSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteContinentSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCountryGroupSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCountrySearchItemFragmentDoc.definitions,
    ...FdrAutocompleteRegionSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteThemeSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteAirportSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCruiseSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteDestinationSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteResortSearchItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrTextSearchAutocompleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrTextSearchAutocomplete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrSearchType' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selected' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrTextAutocomplete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'text' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchType' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '5' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'excludeFromSearchId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selected' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'perPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'itemList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteATSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteHotelSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteRoundTripSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteContinentSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteCountryGroupSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteCountrySearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteRegionSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteThemeSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteAirportSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteCruiseSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteDestinationSearchItem',
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrAutocompleteResortSearchItem',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAutocompleteAtSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteHotelSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteRoundTripSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteContinentSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCountryGroupSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCountrySearchItemFragmentDoc.definitions,
    ...FdrAutocompleteRegionSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteThemeSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteAirportSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteCruiseSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteDestinationSearchItemFragmentDoc.definitions,
    ...FdrAutocompleteResortSearchItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrTextSearchAutocompleteQuery__
 *
 * To run a query within a React component, call `useFdrTextSearchAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrTextSearchAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrTextSearchAutocompleteQuery({
 *   variables: {
 *      text: // value for 'text'
 *      page: // value for 'page'
 *      searchType: // value for 'searchType'
 *      selected: // value for 'selected'
 *   },
 * });
 */
export function useFdrTextSearchAutocompleteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >(FdrTextSearchAutocompleteDocument, options);
}
export function useFdrTextSearchAutocompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrTextSearchAutocompleteQuery,
    FdrTextSearchAutocompleteQueryVariables
  >(FdrTextSearchAutocompleteDocument, options);
}
export type FdrTextSearchAutocompleteQueryHookResult = ReturnType<
  typeof useFdrTextSearchAutocompleteQuery
>;
export type FdrTextSearchAutocompleteLazyQueryHookResult = ReturnType<
  typeof useFdrTextSearchAutocompleteLazyQuery
>;
export type FdrTextSearchAutocompleteQueryResult = Apollo.QueryResult<
  FdrTextSearchAutocompleteQuery,
  FdrTextSearchAutocompleteQueryVariables
>;
