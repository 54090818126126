import React, { ReactElement } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { trackSearch } from '@hotelplan/libs.tracking';
import { FdrFlightSearchControlToggle } from './fdr-flight-search-control-toggle';
import {
  FdrFlightSearchControlWrapper,
  FdrFlightSRLSearchFormWrapper,
} from './fdr-flight-search-control.styles';
import { mapFdrFlightSRLStateToTrackableData } from './fdr-flight-search.mappers';
import { FdrFlightFormContainer } from './form/fdr-flight-form-container';
import { FdrFlightSearchForm } from './form/fdr-flight-search-form';

export function FdrFlightSearchControl(): ReactElement {
  const { mobile } = useDeviceType();

  return (
    <FdrFlightSRLSearchFormWrapper>
      <FdrFlightSearchControlWrapper>
        {!mobile ? (
          <FdrFlightSearchForm
            postSubmit={state => {
              trackSearch(mapFdrFlightSRLStateToTrackableData(state));
            }}
          >
            <FdrFlightFormContainer withCustomLabels />
          </FdrFlightSearchForm>
        ) : (
          <FdrFlightSearchControlToggle />
        )}
      </FdrFlightSearchControlWrapper>
    </FdrFlightSRLSearchFormWrapper>
  );
}
