import styled from 'styled-components';
import { sx2CssThemeFn, SxStyleProp } from '@hotelplan/util.theme.sxc';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { FdrSearchControlFormLayout } from 'fdr/components/candidate/fdr-search-control/form-layout';

export const FdrSearchControlFormLayoutWrapper = styled(
  FdrSearchControlFormLayout
)(
  sx2CssThemeFn({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    pt: 4,
    px: [0, '30px'],
    pb: ['20px', '30px'],
  })
);

export const FdrFlightFormLayoutWrapper = styled.div(
  ({ theme: { colors } }) =>
    sx2CssThemeFn({
      mx: [4, 0],
      '.combobox-autocomplete-input, .open': {
        input: {
          boxShadow: `inset 0 -3px 0 0 ${colors.primary}`,
        },
      },
      '.flight-param-wrapper': {
        'select:focus-visible': {
          outline: `auto 2px`,
          outlineOffset: '-1px',
          outlineColor: colors.defaultText,
        },
      },
    }),
  ({ theme: { media, space } }) => ({
    '.flight-types-wrapper': {
      marginBottom: space[3],
      [media.tablet]: {
        marginBottom: space[4],
      },
    },
  })
);

const inputStyles: SxStyleProp = {
  flex: ['100%', '1', '1 1 405px'],
  '&:not(:last-child)': {
    mr: [0, '10px'],
  },
  '> .input': {
    height: ['45px', '48px'],
  },
  'select button': {
    height: [null, '48px'],
  },
  '> .icon:not(.chevron)': {
    variant: ['icons.md', 'icons.lg'],
  },
};

export const FdrFlightParamsWrapper = styled.div<{
  activeType: FdrFlightType | null;
}>(
  sx2CssThemeFn({
    display: 'flex',
    '.flight-param': inputStyles,
    flexWrap: ['wrap', 'nowrap'],
    mt: [null, 4],
  }),
  ({ activeType }) =>
    activeType === FdrFlightType.OpenJaw
      ? sx2CssThemeFn({
          borderTop: [null, '1px solid'],
          borderColor: [null, 'borderColor'],
          pt: [2, 5],
        })
      : {}
);

export const FdrFlightParamSubmitWrapper = styled.div(
  sx2CssThemeFn(inputStyles, {
    flex: ['100%', '1', '340px'],
    display: ['none', 'flex'],
    alignItems: 'flex-end',
  })
);

export const FdrFlightParamSubmitButton = styled(BsButton)(
  sx2CssThemeFn({
    width: '100%',
    height: [null, '48px'],
  })
);

export const FdrFlightFormFooterWrapper = styled.div(
  sx2CssThemeFn({
    textAlign: 'center',
    display: [null, 'none'],
  })
);

export const FdrSubmitButton = styled(BsButton)({
  width: '100%',
});

export const FdrCloseButton = styled(BsButton)({
  margin: '0 auto',
  padding: '8px 0',
});

export const FdrFlightFormFooterOptions = styled.div(
  ({ theme: { space } }) => ({
    display: 'flex',
    marginBottom: space[2],
    justifyContent: 'flex-end',
  })
);

export const FdrFlightFormFooterToggleOptionsButtonWrapper = styled(BsButton)<{
  isOptionsOpen: boolean;
}>(({ isOptionsOpen, theme: { space, fontSizes, colors } }) => ({
  paddingTop: 0,
  paddingBottom: '8px',
  '.flight-options-button-text': {
    marginRight: space[2],
    fontSize: fontSizes[1],
    color: 'initial',
  },
  '.flight-options-button-icon': {
    width: '16px',
    height: '10px',
    color: colors.black,
    transform: isOptionsOpen ? 'rotate(180deg)' : 'none',
  },
}));

export const FdrFlightClassFieldWrapper = styled.div(
  sx2CssThemeFn({
    marginBottom: [3, '20px'],
    '.flight-class-field-options': {
      '.input': {
        py: ['10px', '13px'],
      },
    },
  })
);
