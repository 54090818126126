import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { FdrSearchHistoryType } from '@hotelplan/supergraph-api';
import {
  isFdrFlightSearchHistory,
  isFdrProductSearchHistory,
} from 'fdr/components/local/fdr-history/fdr-history.types';
import {
  FdrSearchHistoryDocument,
  FdrSearchHistoryQuery,
  FdrSearchHistoryQueryVariables,
} from 'fdr/schemas/query/history/fdr-search-history.generated';
import {
  FLIGHT_ICON_NAME,
  getIconName,
  getSubTitleFromFlightSearchHistoryItem,
  getSubTitleFromProductSearchHistoryItem,
  getTitleFromFlightSearchHistoryItem,
  getTitleFromProductSearchHistoryItem,
} from './fdr-search-history-group.utils';
import { TSearchHistoryItem } from './fdr-search-history.types';
import { SEARCH_HISTORY_PER_PAGE, useSearchHistory } from './useSearchHistory';

const useSearchedProductHistory = (
  types: FdrSearchHistoryType[] = [
    FdrSearchHistoryType.HotelOnlySearch,
    FdrSearchHistoryType.PackageSearch,
    FdrSearchHistoryType.FlightSearch,
  ],
  perPage: number = SEARCH_HISTORY_PER_PAGE
) => {
  const [t] = useTranslation('common');

  const mapToVariables = useCallback(
    (page: number) => {
      return {
        input: {
          filter: {
            types,
          },
          pagination: {
            page,
            perPage,
          },
        },
      };
    },
    [types, perPage]
  );

  const mapToPage = useCallback(
    ({ fdrSearchHistory }: FdrSearchHistoryQuery) => {
      return fdrSearchHistory.pagination;
    },
    []
  );

  const mapToItems = useCallback(
    ({ fdrSearchHistory }: FdrSearchHistoryQuery): TSearchHistoryItem[] => {
      return fdrSearchHistory?.entries?.map(item => {
        if (isFdrProductSearchHistory(item)) {
          return {
            icon: getIconName(item.productSearchCriteria.travelType),
            title: getTitleFromProductSearchHistoryItem(item, t),
            subtitle: getSubTitleFromProductSearchHistoryItem(item, t),
            link: item?.webMeta?.link,
          };
        }

        if (isFdrFlightSearchHistory(item)) {
          return {
            icon: FLIGHT_ICON_NAME,
            title: getTitleFromFlightSearchHistoryItem(item, t),
            subtitle: getSubTitleFromFlightSearchHistoryItem(item, t),
            link: item?.webMeta?.link,
          };
        }

        return null;
      });
    },
    [t]
  );

  return useSearchHistory<
    FdrSearchHistoryQuery,
    FdrSearchHistoryQueryVariables,
    TSearchHistoryItem
  >({
    documentNode: FdrSearchHistoryDocument,
    mapToVariables,
    mapToPage,
    mapToItems,
  });
};

export default useSearchedProductHistory;
