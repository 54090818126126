import styled from 'styled-components';
import { SelectInput } from '@hotelplan/components.common.select-input';
import React from 'react';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrCabinClassInput = styled(SelectInput)<{
  customLabel?: React.ReactNode;
}>(({ customLabel }) =>
  sx2CssThemeFn({
    '.input': {
      height: '48px',
    },
    '.status-icon': {
      top: Boolean(customLabel) && `calc(50% + 14px)`,
    },
  })
);
