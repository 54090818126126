import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrHistoryTravellersFragment = {
  __typename?: 'FdrHistoryTravellersCriteria';
  adults: number;
  childrenDobs?: Array<any> | null;
};

export const FdrHistoryTravellersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryTravellers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryTravellersCriteria' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'childrenDobs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
