import { mapExactTravelPeriodToFormTravelDates } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { FdrFlightPartition } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { FdrHistoryFlightFragment } from 'fdr/schemas/query/history/fdr-search-history.generated';

export function mapFdrHistoryFlightsToFlightPartitions(
  flights: Array<FdrHistoryFlightFragment>
): Array<FdrFlightPartition> {
  return flights.map(flight => {
    return {
      flightClass: flight.flightClass,
      travelDates: mapExactTravelPeriodToFormTravelDates(
        flight?.departureDate,
        flight?.returnDate
      ),
      arrivalAirport: flight?.arrivalAirport,
      departureAirport: flight?.departureAirport,
    };
  });
}
