import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// it's needed to update the form when the search query changes via url transition
// e.g. when user clicks on search history item
export function useFdrSearchFormRerender(): string {
  const { query } = useRouter();
  const searchQuery = query['s'];

  const [formUpdateKey, setFormUpdateKey] = useState<string>(() =>
    JSON.stringify(searchQuery)
  );

  useEffect(() => {
    const nextKey = JSON.stringify(searchQuery);
    setFormUpdateKey(prevKey => prevKey !== nextKey && nextKey);
  }, [searchQuery]);

  return formUpdateKey;
}
