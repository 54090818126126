import React, { ReactElement, useContext } from 'react';
import { trackSearch } from '@hotelplan/libs.tracking';
import CurrentSearchContext from 'components/domain/currentSearch/CurrentSearchContext';
import { FdrFlightCurrentSearchField } from './fdr-flight-current-search-field';
import { mapFdrFlightSRLStateToTrackableData } from './fdr-flight-search.mappers';
import { FdrFlightFormContainer } from './form/fdr-flight-form-container';
import { FdrFlightSearchForm } from './form/fdr-flight-search-form';

export function FdrFlightSearchControlToggle(): ReactElement {
  const { closeSearchControl, isSearchControlShowed, showSearchControl, ref } =
    useContext(CurrentSearchContext);

  return (
    <div ref={ref}>
      {isSearchControlShowed ? (
        <FdrFlightSearchForm
          postSubmit={state => {
            trackSearch(mapFdrFlightSRLStateToTrackableData(state));
          }}
        >
          <FdrFlightFormContainer
            withCustomLabels
            handleCancel={closeSearchControl}
          />
        </FdrFlightSearchForm>
      ) : (
        <FdrFlightCurrentSearchField handleEdit={showSearchControl} />
      )}
    </div>
  );
}
