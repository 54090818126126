import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import {
  FdrFlightFormFieldTypes,
  useFdrFlightFormContext,
} from 'fdr/components/local/fdr-flight-search/use-fdr-flight-form-context';
import {
  FdrFlightTypeRadioInput,
  FdrFlightTypesWrapper,
  FdrTextWrapper,
} from './fdr-flight-types.styles';

const flightTypesMap: Array<{
  type: FdrFlightType;
  label: string;
  testId?: string;
}> = [
  {
    type: FdrFlightType.Return,
    label: 'forms:returnFlight.radioInput',
  },
  {
    type: FdrFlightType.OneWay,
    label: 'forms:oneWayFlight.radioInput',
  },
  {
    type: FdrFlightType.OpenJaw,
    label: 'forms:openJawFlight.radioInput',
  },
];

export function FdrFlightTypes(): ReactElement {
  const [t] = useTranslation('forms');
  const { values, onFlightFormChange } = useFdrFlightFormContext();

  return (
    <FdrFlightTypesWrapper>
      {flightTypesMap.map(flightType => (
        <FdrFlightTypeRadioButton
          key={flightType.type}
          label={t(flightType.label)}
          flightType={flightType.type}
          checked={values.flightType === flightType.type}
          onChange={(nextFlightType): void => {
            onFlightFormChange(
              nextFlightType,
              FdrFlightFormFieldTypes.FlightTypeField
            );
          }}
          onKeyPress={e => {
            if (e.key === ENTER_KEY) {
              onFlightFormChange(
                flightType.type,
                FdrFlightFormFieldTypes.FlightTypeField
              );
            }
          }}
        />
      ))}
    </FdrFlightTypesWrapper>
  );
}

interface IFdrFlightTypeRadioButtonProps {
  flightType: FdrFlightType;
  checked: boolean;
  label: string;
  testId?: string;
  onChange(value: FdrFlightType): void;
  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void;
}

function FdrFlightTypeRadioButton({
  flightType,
  checked,
  label,
  testId,
  onChange,
  onKeyPress,
}: IFdrFlightTypeRadioButtonProps): ReactElement {
  return (
    <FdrFlightTypeRadioInput
      tabIndex={-1}
      hideLabel
      id={flightType}
      className="flight-type-label"
      checked={checked}
      name="flightTypes"
      value={flightType}
      testId={testId}
      onChange={onChange}
      renderCustomControl={() => (
        <FdrTextWrapper checked={checked} onKeyDown={onKeyPress}>
          {label}
        </FdrTextWrapper>
      )}
    />
  );
}
