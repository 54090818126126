import React from 'react';
import styled from 'styled-components';
import { FakeInput } from '@hotelplan/components.common.inputs';
import {
  FdrAutocompleteComboboxInput,
  IFdrAutocompleteComboboxInputProps,
} from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-combobox';
import { FdrAirportFragment } from 'fdr/schemas/fragment/regular/fdr-airport.generated';

export const FdrFlightAirportFakeInput = styled(FakeInput)(
  ({ theme: { media } }) => ({
    input: {
      height: '45px',
      [media.tablet]: {
        height: '48px',
      },
    },
  })
);

export const FdrFlightAirportComboboxInput = styled(
  FdrAutocompleteComboboxInput
)<{
  customLabel?: React.ReactNode;
}>(({ customLabel }) => ({
  input: {
    height: '48px',
  },
  '.autocomplete-dropdown': {
    top: Boolean(customLabel) ? '28px' : 0,
  },
})) as React.FC<IFdrAutocompleteComboboxInputProps<FdrAirportFragment>>;
