import { track } from '@hotelplan/libs.tracking-events';

enum SelectDestination {
  SELECT_EVENT_TYPE = 'search_suggestion',
  SELECT_ACTION = 'select',
  SELECT_COMPONENT = 'search-suggestion',
}

export const trackSelectDestination = (
  label: string,
  position: string,
  category: string,
  searchSuggestionPosition: number,
  language: string
) => {
  track({
    event: SelectDestination.SELECT_EVENT_TYPE,
    action: SelectDestination.SELECT_ACTION,
    label,
    category,
    component: SelectDestination.SELECT_COMPONENT,
    position,
    language,
    _clear: true,
    search_suggestion_position: searchSuggestionPosition,
  });
};
