import { TFunction, useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import CustomLabel from 'components/domain/CustomLabel';
import { FdrFlightTravelDates } from 'fdr/components/local/fdr-flight-search/flight-travel-dates/fdr-flight-travel-dates';
import {
  FdrFlightPartition,
  FdrFlightPartitionsWrapper,
} from './fdr-flight-partitions.styles';
import { useFdrFlightPartitions } from './use-fdr-flight-partitions';

interface IFdrFlightPartitionsProps {
  renderDepartureAirportField: (
    t: TFunction,
    partitionId: number,
    withCustomLabels?: boolean
  ) => React.ReactNode;
  renderArrivalAirportField: (
    t: TFunction,
    partitionId: number,
    withCustomLabels?: boolean
  ) => React.ReactNode;
  withCustomLabels?: boolean;
}

export function FdrFlightPartitions({
  renderDepartureAirportField,
  renderArrivalAirportField,
  withCustomLabels,
}: IFdrFlightPartitionsProps): ReactElement {
  const [t] = useTranslation('forms');

  const { value: flightPartitions } = useFdrFlightPartitions();

  const [activeType] = useField<FdrFlightType | null>('flightType');

  const partitionsToRender =
    activeType === FdrFlightType.OpenJaw
      ? flightPartitions
      : flightPartitions?.slice(0, 1);

  const shouldShowPartitionHeadings = partitionsToRender
    ? partitionsToRender.length >= 2
    : false;

  return (
    <>
      {partitionsToRender &&
        partitionsToRender.map(
          (partition, index): React.ReactNode => (
            <FdrFlightPartition key={index}>
              {shouldShowPartitionHeadings && (
                <h4 className="partition-title">
                  {`${t('flightSegment.heading')} ${index + 1}`}
                </h4>
              )}
              <FdrFlightPartitionsWrapper
                shouldShowPartitionHeadings={shouldShowPartitionHeadings}
              >
                <div className="airport-field">
                  {renderDepartureAirportField(t, index, withCustomLabels)}
                </div>
                <div className="airport-field">
                  {renderArrivalAirportField(t, index, withCustomLabels)}
                </div>
                <div className="flight-travel-dates-field">
                  <FdrFlightTravelDates
                    partitionIndex={index}
                    customLabel={
                      withCustomLabels ? (
                        <CustomLabel htmlFor="travelDates">
                          {t('forms:flightTravelDates.label')}
                        </CustomLabel>
                      ) : null
                    }
                  />
                </div>
              </FdrFlightPartitionsWrapper>
            </FdrFlightPartition>
          )
        )}
    </>
  );
}
