import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useMemoryPagination } from '@hotelplan/libs.hooks';
import { U_Extended_TravelType } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { trackSelectDestination } from './fdr-select-destination.tracking';
import { TopDestinationShowMoreLink } from './fdr-top-destination-autocomplete-suggestions.styles';
import { FdrTopDestinationGeoNameQueryComponent } from './fdr-top-destination-geo-name-query-component';
import FdrTopDestinationItem from './fdr-top-destination-item';
import useFdrTopDestinationAutocompleteIds from './use-fdr-top-destination-autocomplete-ids';

const DESTINATIONS_PER_PAGE = 5;

interface ITopDestinationAutocompleteSuggestionsProps {
  firstPageCount: number;
  travelType: U_Extended_TravelType;
}

export const FdrTopDestinationAutocompleteSuggestions: React.FC<ITopDestinationAutocompleteSuggestionsProps> =
  ({ firstPageCount, travelType }) => {
    const [, { language }] = useTranslation();
    const pageType = usePageType();
    const isHomePage = pageType === EPageType.Home;

    const trackingLoadMoreLabel = 'Mehr laden';
    const trackingLoadMorePosition = isHomePage ? 'home' : 'srl';
    const trackingLoadMoreCategory = 'more';

    const [geoNameMap, setGeoNameMap] = useState(new Map<string, string>());
    const destinationIds = useFdrTopDestinationAutocompleteIds(travelType);
    const { items, nextPage, hasMore } = useMemoryPagination<string>(
      Array.from(geoNameMap.keys())
        .filter(id => geoNameMap.get(id) !== null)
        .sort((a, b) => {
          return destinationIds.indexOf(a) - destinationIds.indexOf(b);
        }),
      {
        perPage: DESTINATIONS_PER_PAGE,
        firstPageCount,
        enable: !!destinationIds.length,
      }
    );
    const [t] = useTranslation('common');

    const onLoad = (id: string, name: string | null) => {
      geoNameMap.set(id, name);
      setGeoNameMap(new Map(geoNameMap));
    };

    return (
      <>
        {geoNameMap.size < destinationIds.length &&
          destinationIds.map(id => {
            return (
              <FdrTopDestinationGeoNameQueryComponent
                key={`top-destination-query-${id}`}
                id={id}
                onLoad={onLoad}
              />
            );
          })}
        {geoNameMap.size === destinationIds.length && (
          <div>
            {items.map(id => {
              return (
                <FdrTopDestinationItem
                  items={[{ id, name: geoNameMap.get(id) }]}
                  name={geoNameMap.get(id)}
                  key={`top-destination-${id}`}
                />
              );
            })}
            {hasMore && (
              <TopDestinationShowMoreLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  trackSelectDestination(
                    trackingLoadMoreLabel,
                    trackingLoadMorePosition,
                    trackingLoadMoreCategory,
                    1,
                    language
                  );
                  nextPage();
                }}
              >
                {t('load.more')}
              </TopDestinationShowMoreLink>
            )}
          </div>
        )}
      </>
    );
  };
