import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrHistoryTravellersFragmentDoc } from 'fdr/schemas/fragment/travellers/fdr-history-travellers.generated';
import { FdrAirportFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-airport.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrSearchHistoryQueryVariables = Types.Exact<{
  input: Types.FdrSearchHistoryInput;
}>;

export type FdrSearchHistoryQuery = {
  __typename?: 'Query';
  fdrSearchHistory: {
    __typename?: 'FdrSearchHistoryResponse';
    success: boolean;
    message?: string | null;
    entries?: Array<
      | {
          __typename: 'FdrFlightSearchHistory';
          id: string;
          webMeta?: {
            __typename?: 'FdrFlightSearchResultWebMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
          flightSearchCriteria?: {
            __typename?: 'FdrHistoryFlightSearchCriteria';
            flightType: Types.FdrFlightType;
            travellers: {
              __typename?: 'FdrHistoryTravellersCriteria';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
            flights: Array<{
              __typename?: 'FdrHistoryFlightCriteria';
              flightClass: Types.FdrFlightClass;
              departureDate: any;
              returnDate?: any | null;
              departureAirport: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              };
              arrivalAirport: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              };
            }>;
          } | null;
        }
      | {
          __typename: 'FdrProductSearchHistory';
          id: string;
          webMeta?: {
            __typename?: 'FdrSearchResultWebMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
          productSearchCriteria?: {
            __typename?: 'FdrHistoryProductSearchCriteria';
            rooms: number;
            travelType: Types.FdrProductTravelType;
            period: {
              __typename?: 'FdrHistoryTravelPeriodCriteria';
              departureDate: any;
              returnDate: any;
              durations?: Array<number> | null;
            };
            travellers: {
              __typename?: 'FdrHistoryTravellersCriteria';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
            destinations?: {
              __typename?: 'FdrHistoryTravelDestinationsCriteria';
              geo?: Array<
                | { __typename?: 'FdrDestination'; name: string; id: string }
                | { __typename?: 'FdrCountry'; name: string; id: string }
                | { __typename?: 'FdrContinent'; name: string; id: string }
                | { __typename?: 'FdrCountryGroup'; name: string; id: string }
                | { __typename?: 'FdrRegion'; name: string; id: string }
                | { __typename?: 'FdrResort'; name: string; id: string }
              > | null;
              products?: Array<
                | { __typename?: 'FdrCruise'; name: string; id: string }
                | { __typename?: 'FdrHotel'; name: string; id: string }
                | { __typename?: 'FdrRoundTrip'; name: string; id: string }
                | {
                    __typename?: 'FdrAdventureTravel';
                    name: string;
                    id: string;
                  }
              > | null;
            } | null;
          } | null;
        }
      | { __typename?: 'FdrTextSearchHistory' }
    > | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export type FdrProductSearchHistoryFragment = {
  __typename: 'FdrProductSearchHistory';
  id: string;
  webMeta?: {
    __typename?: 'FdrSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  productSearchCriteria?: {
    __typename?: 'FdrHistoryProductSearchCriteria';
    rooms: number;
    travelType: Types.FdrProductTravelType;
    period: {
      __typename?: 'FdrHistoryTravelPeriodCriteria';
      departureDate: any;
      returnDate: any;
      durations?: Array<number> | null;
    };
    travellers: {
      __typename?: 'FdrHistoryTravellersCriteria';
      adults: number;
      childrenDobs?: Array<any> | null;
    };
    destinations?: {
      __typename?: 'FdrHistoryTravelDestinationsCriteria';
      geo?: Array<
        | { __typename?: 'FdrDestination'; name: string; id: string }
        | { __typename?: 'FdrCountry'; name: string; id: string }
        | { __typename?: 'FdrContinent'; name: string; id: string }
        | { __typename?: 'FdrCountryGroup'; name: string; id: string }
        | { __typename?: 'FdrRegion'; name: string; id: string }
        | { __typename?: 'FdrResort'; name: string; id: string }
      > | null;
      products?: Array<
        | { __typename?: 'FdrCruise'; name: string; id: string }
        | { __typename?: 'FdrHotel'; name: string; id: string }
        | { __typename?: 'FdrRoundTrip'; name: string; id: string }
        | { __typename?: 'FdrAdventureTravel'; name: string; id: string }
      > | null;
    } | null;
  } | null;
};

export type FdrFlightSearchHistoryFragment = {
  __typename: 'FdrFlightSearchHistory';
  id: string;
  webMeta?: {
    __typename?: 'FdrFlightSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  } | null;
  flightSearchCriteria?: {
    __typename?: 'FdrHistoryFlightSearchCriteria';
    flightType: Types.FdrFlightType;
    travellers: {
      __typename?: 'FdrHistoryTravellersCriteria';
      adults: number;
      childrenDobs?: Array<any> | null;
    };
    flights: Array<{
      __typename?: 'FdrHistoryFlightCriteria';
      flightClass: Types.FdrFlightClass;
      departureDate: any;
      returnDate?: any | null;
      departureAirport: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      };
      arrivalAirport: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      };
    }>;
  } | null;
};

export type FdrHistoryFlightFragment = {
  __typename?: 'FdrHistoryFlightCriteria';
  flightClass: Types.FdrFlightClass;
  departureDate: any;
  returnDate?: any | null;
  departureAirport: {
    __typename?: 'FdrAirport';
    name: string;
    isGroup: boolean;
    iata: string;
    codes?: Array<string> | null;
    coordinates?: {
      __typename?: 'FdrCoordinates';
      longitude: number;
      latitude: number;
    } | null;
  };
  arrivalAirport: {
    __typename?: 'FdrAirport';
    name: string;
    isGroup: boolean;
    iata: string;
    codes?: Array<string> | null;
    coordinates?: {
      __typename?: 'FdrCoordinates';
      longitude: number;
      latitude: number;
    } | null;
  };
};

export const FdrProductSearchHistoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductSearchHistory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductSearchHistory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productSearchCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'period' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'departureDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'returnDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'durations' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'travellers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adults' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'childrenDobs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destinations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHistoryFlightFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryFlight' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryFlightCriteria' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'flightClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAirport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAirport' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAirportFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightSearchHistoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightSearchHistory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSearchHistory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flightSearchCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'flightType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'travellers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHistoryTravellers' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHistoryFlight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrHistoryTravellersFragmentDoc.definitions,
    ...FdrHistoryFlightFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrSearchHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrSearchHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrSearchHistoryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrSearchHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'FdrProductSearchHistory',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrProductSearchHistory',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'FdrFlightSearchHistory',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrFlightSearchHistory',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrProductSearchHistoryFragmentDoc.definitions,
    ...FdrFlightSearchHistoryFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrSearchHistoryQuery__
 *
 * To run a query within a React component, call `useFdrSearchHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrSearchHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrSearchHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrSearchHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrSearchHistoryQuery,
    FdrSearchHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrSearchHistoryQuery,
    FdrSearchHistoryQueryVariables
  >(FdrSearchHistoryDocument, options);
}
export function useFdrSearchHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrSearchHistoryQuery,
    FdrSearchHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrSearchHistoryQuery,
    FdrSearchHistoryQueryVariables
  >(FdrSearchHistoryDocument, options);
}
export type FdrSearchHistoryQueryHookResult = ReturnType<
  typeof useFdrSearchHistoryQuery
>;
export type FdrSearchHistoryLazyQueryHookResult = ReturnType<
  typeof useFdrSearchHistoryLazyQuery
>;
export type FdrSearchHistoryQueryResult = Apollo.QueryResult<
  FdrSearchHistoryQuery,
  FdrSearchHistoryQueryVariables
>;
