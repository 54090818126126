import {
  getDurationValuesBySearchType,
  updateDuration,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { FdrFlightType, FdrTravelPeriodType } from '@hotelplan/supergraph-api';
import { IFdrFlightSrlControlState } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { prepareDatesInFdrFlightPartitions } from 'fdr/components/local/fdr-flight-search/flight-partitions/fdr-flight-partitions.utils';

export function mergeFdrFlightTypeToFdrFlightSearchState(
  currentState: IFdrFlightSrlControlState,
  nextFlightType: FdrFlightType
): IFdrFlightSrlControlState {
  let flightPartitions = currentState.flightPartitions?.map(partition => {
    if (!partition.travelDates) return { ...partition };

    const durationValues = getDurationValuesBySearchType(
      FdrTravelPeriodType.Exact
    );

    const updatedDuration = updateDuration(
      partition.travelDates.departureDate,
      partition.travelDates.departureDate,
      partition.travelDates.duration,
      durationValues,
      FdrTravelPeriodType.Exact
    );

    return {
      ...partition,
      travelDates: {
        ...partition.travelDates,
        returnDate:
          nextFlightType === FdrFlightType.Return
            ? partition.travelDates.departureDate
            : null,
        duration: updatedDuration,
      },
    };
  });

  if (nextFlightType === FdrFlightType.OpenJaw) {
    flightPartitions = prepareDatesInFdrFlightPartitions(flightPartitions);
  }

  return {
    ...currentState,
    flightType: nextFlightType,
    flightPartitions,
  };
}
