import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { Image } from '@hotelplan/components.common.image';

export const SearchHistoryWrapper = styled.div.attrs({
  'data-id': `search-history`,
})(
  sx2CssThemeFn({
    maxHeight: '700px',
    overflow: ['initial', 'auto'],
    bg: 'white',
    p: 4,
    boxShadow: [null, 'cardShadow'],
  })
);

export const SearchHistoryGroupWrapper = styled.div(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: 'flex',
      flexDirection: 'column',
      marginBottom: [5, 6],
      '.search-history-group-title': {
        ...FONT_SIZE.S,
        marginBottom: [3, 4],
      },
    })
);

export const SearchHistoryLinkWrapper = styled(BsLink)(
  ({ theme: { colors } }) =>
    sx2CssThemeFn({
      marginBottom: [3, 4],
      display: 'flex',
      ':hover': {
        color: colors.interactionPrimary,
        '.icon': {
          color: colors.interactionPrimary,
        },
      },
    })
);

export const SearchHistoryIconWrapper = styled(BsIcon)<{ vertical: boolean }>(
  sx2CssThemeFn({
    mt: 1,
    mr: [3, 4],
    width: '45px',
    height: '24px',
  }),
  ({ vertical }) => (vertical ? { marginTop: '8px', height: '60px' } : {})
);

export const SearchHistoryLinkItemWrapper = styled.div(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      width: ['calc(100% - 45px - 16px)', 'calc(100% - 45px - 24px)'],
      '.subtitle': {
        ...FONT_SIZE.MEDIUM,
        color: 'darkGray',
      },
    })
);

export const SearchHistoryLinkButtonWrapper = styled.div.attrs({
  className: 'search-history-paginator',
})(({ theme: { colors, space, FONT_SIZE } }) => ({
  display: 'flex',
  borderBottom: '1px solid',
  borderColor: colors.lightGreyFourth,
  paddingBottom: space[3],
  '.search-history-button': {
    ...FONT_SIZE.LARGE,
    marginLeft: 'auto',
    paddingBottom: space[3],
    transform: 'translate(0)',
    '.icon': {
      width: space[3],
      height: space[3],
      marginLeft: space[2],
      '&:hover,&:focus': {
        color: colors.defaultText,
      },
    },
  },
}));

export const FdrSearchHistoryLoadMoreButton = styled(BsButton)(
  ({ theme: { colors } }) =>
    sx2CssThemeFn({
      '&:disabled': {
        bg: 'transparent',
        border: 'none',
        textDecoration: 'underline',
        textDecorationColor: colors.linkUnderlineColor,
      },
    })
);

export const SearchHistoryLoginLinksWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    marginBottom: [3, 4],
  })
);

export const SearchHistoryLoginLinksImage = styled(Image)(
  sx2CssThemeFn({
    flexShrink: 0,
    width: '50px',
    height: '38px',
    marginRight: 3,
    marginTop: 1,
  })
);

export const SearchHistoryLinks = styled.p(
  sx2CssThemeFn({
    variant: 'text.richText',
  })
);
