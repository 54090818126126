import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { useSearchState } from '@hotelplan/fdr.lib.search.with-state';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  EmptyResultsModalSource,
  EmptyResultsModalTarget,
  HelpModuleEventType,
  trackEmptyResultModalClick,
  trackHelpModule,
} from '@hotelplan/libs.tracking';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FdrLink, FdrProductTravelType } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import CurrentSearchContext from 'components/domain/currentSearch/CurrentSearchContext';
import { openHelpOverlay } from 'fdr/components/local/fdr-help-overlay';
import { IFdrORLState } from 'fdr/components/local/fdr-orl/fdr-orl-search-form.types';

const FdrEmptyResultsWrap = styled.div(
  ({ theme }) => theme.modals.notificationCardWrapWithError || {},
  ({ theme: { space, fontSizes } }) => ({
    paddingTop: space[3],
    '.title': {
      fontSize: fontSizes[3],
      marginBottom: space[2],
    },
    '.description': {
      marginBottom: space[4],
      maxWidth: '600px',
    },
  })
);

const FdrEmptyResultsWrapAlternative = styled(FdrEmptyResultsWrap)(
  sx2CssThemeFn({
    p: [0, 4],
    '.notification-content': {
      maxWidth: '100%',
      margin: 'initial',
      textAlign: 'initial',
    },
    '.title': {
      fontSize: [4, '30px'],
      lineHeight: ['34px', '42px'],
      marginBottom: 4,
    },
    '.description': {
      fontSize: [2, 3],
      marginBottom: 4,
      maxWidth: '600px',
    },
  })
);

const FdrLinkAsButton = styled(BsLink)(({ theme }) => theme.link.cta || {}, {
  width: '100%',
  cursor: 'pointer',
});

const FdrSecondaryButton = styled(BsButton)(
  sx2CssThemeFn({
    width: '100%',
    mt: 3,
  })
);

const FdrSecondaryButtonLink = styled(FdrRoute)({
  width: '100%',
});

const FdrButtonAsLink = styled(BsButton)(
  sx2CssThemeFn({
    width: '100%',
    py: 3,
  })
);

const FdrSearchButton = styled(BsButton)({
  width: '100%',
});

export interface IFdrEmptyBlockProps {
  onRenewSearch: () => void;
  onHelp?(): void;
  title?: React.ReactNode;
  description?: React.ReactNode;
  buttonContent?: React.ReactNode;
  className?: string;
}

interface IFdrEmptyBlockAlternativeProps extends React.PropsWithChildren<{}> {
  alternativeUrl?: FdrLink;
  alternativeTravelType?: FdrProductTravelType;
}

const FdrEmptyBlock: React.FC<
  IFdrEmptyBlockProps &
    IFdrEmptyBlockAlternativeProps &
    React.PropsWithChildren<{}>
> = props => {
  const [t] = useTranslation('results');
  const {
    children,
    onRenewSearch,
    onHelp: onHelpHandler,
    className,
    buttonContent = t('results:noOffers.searchButton'),
    alternativeTravelType,
    alternativeUrl,
  } = props;

  const {
    state: { filtersChanged },
  } = useSearchState<IFdrORLState>();

  let {
    title = t('results:noOffers.title'),
    description = t('results:noOffers.description'),
  } = props;
  let secondaryButtonText = t('results:noOffers.hotels.secondaryButton');

  const isAlternativeTravelTypeHotel =
    alternativeTravelType === FdrProductTravelType.HotelOnly;
  const isAlternativeTravelTypePackage =
    alternativeTravelType === FdrProductTravelType.Package;

  if (alternativeUrl && !filtersChanged) {
    if (isAlternativeTravelTypeHotel) {
      title = t('results:noOffers.hotels.title');
      description = t('results:noOffers.hotels.text');
    } else if (isAlternativeTravelTypePackage) {
      title = t('results:noOffers.packages.title');
      description = t('results:noOffers.packages.text');
      secondaryButtonText = t('results:noOffers.packages.secondaryButton');
    }
  }

  const handleAlternativePrimaryButtonClick = () => {
    onRenewSearch();
    if (!isAlternativeTravelTypeHotel && !isAlternativeTravelTypePackage)
      return;
    trackEmptyResultModalClick({
      source: isAlternativeTravelTypeHotel
        ? EmptyResultsModalSource.HOTELS
        : EmptyResultsModalSource.PACKAGES,
      target: EmptyResultsModalTarget.ADAPT_SEARCH,
    });
  };

  const handleAlternativeSecondaryButtonClick = () => {
    if (!isAlternativeTravelTypeHotel && !isAlternativeTravelTypePackage)
      return;
    trackEmptyResultModalClick({
      source: isAlternativeTravelTypeHotel
        ? EmptyResultsModalSource.HOTELS
        : EmptyResultsModalSource.PACKAGES,
      target: isAlternativeTravelTypeHotel
        ? EmptyResultsModalTarget.SWITCH_TO_HOTELS
        : EmptyResultsModalTarget.SWITCH_TO_PACKAGES,
    });
  };

  function onHelp() {
    onHelpHandler?.();
    openHelpOverlay();
    trackHelpModule(HelpModuleEventType.OPEN, false, `noresults`);
  }

  const EmptyResultsWrapVariant = alternativeUrl
    ? FdrEmptyResultsWrapAlternative
    : FdrEmptyResultsWrap;

  return (
    <EmptyResultsWrapVariant className={className} data-id="noOffers">
      <div className="notification-content">
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </div>
      {alternativeUrl ? (
        <>
          <FdrLinkAsButton onClick={handleAlternativePrimaryButtonClick}>
            {t('results:noOffers.primaryButton')}
          </FdrLinkAsButton>
          <FdrSecondaryButtonLink link={alternativeUrl} scroll={false}>
            <FdrSecondaryButton
              variant={E_BUTTON_TYPE.SECONDARY}
              onClick={handleAlternativeSecondaryButtonClick}
            >
              {secondaryButtonText}
            </FdrSecondaryButton>
          </FdrSecondaryButtonLink>
        </>
      ) : (
        <>
          <FdrSearchButton onClick={onRenewSearch}>
            {buttonContent}
          </FdrSearchButton>
          <FdrButtonAsLink variant={E_BUTTON_TYPE.LINK_BUTTON} onClick={onHelp}>
            {t('results:noOffers.help')}
          </FdrButtonAsLink>
        </>
      )}
      {children}
    </EmptyResultsWrapVariant>
  );
};

const FdrEmptyBlockModalWrapper = styled(WhiteBoxModal)<{
  isAlternative: boolean;
  mobile: boolean;
}>(({ isAlternative, mobile }) =>
  sx2CssThemeFn({
    '.modal-header': {
      border: 'none',
    },
    '.modal-wrapper': {
      variant: 'modals.smallModalWrapper',
      ...(isAlternative ? { borderRadius: 4 } : {}),
      ...(isAlternative && mobile ? { alignSelf: 'center', mx: 3, p: 4 } : {}),
    },
    '.modal-body': {
      pt: 0,
      minWidth: [null, '500px'],
    },
  })
);

export interface IFdrEmptyResultsModalProps extends IFdrEmptyBlockProps {
  isModalShowed: boolean;
  onClose?(): void;
}

export const FdrEmptyResultsModal: React.FC<
  IFdrEmptyResultsModalProps &
    IFdrEmptyBlockAlternativeProps &
    React.PropsWithChildren<{}>
> = ({
  children,
  onRenewSearch,
  isModalShowed,
  onClose,
  title,
  description,
  buttonContent,
  ...alternativeProps
}) => {
  const [t] = useTranslation('results');
  const { mobile } = useDeviceType();

  return (
    <FdrEmptyBlockModalWrapper
      title=""
      name="emptyResults"
      data-id="empty-results-modal"
      label={t('results:emptyResultsModal.label') as string}
      show={isModalShowed}
      onClose={onClose}
      isAlternative={!!alternativeProps.alternativeUrl}
      mobile={mobile}
    >
      <FdrEmptyBlock
        onRenewSearch={onRenewSearch}
        title={title}
        description={description}
        buttonContent={buttonContent}
        {...alternativeProps}
      >
        {children}
      </FdrEmptyBlock>
    </FdrEmptyBlockModalWrapper>
  );
};

const FdrEmptyResults: React.FC<IFdrEmptyBlockAlternativeProps> = props => {
  const { children, ...alternativeProps } = props;
  const { ref, showSearchControl } = useContext(CurrentSearchContext);
  const { mobile } = useDeviceType();
  const [isModalShowed, , closeModal] = useToggleState(
    !!alternativeProps.alternativeUrl || !mobile
  );

  const onRenewSearch = () => {
    closeModal();
    showSearchControl();
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 0);
  };

  return mobile && !alternativeProps.alternativeUrl ? (
    <FdrEmptyBlock
      onRenewSearch={onRenewSearch}
      onHelp={closeModal}
      {...alternativeProps}
    >
      {children}
    </FdrEmptyBlock>
  ) : (
    <FdrEmptyResultsModal
      onRenewSearch={onRenewSearch}
      onHelp={closeModal}
      isModalShowed={isModalShowed}
      onClose={alternativeProps.alternativeUrl ? undefined : closeModal}
      {...alternativeProps}
    >
      {children}
    </FdrEmptyResultsModal>
  );
};

export default FdrEmptyResults;
