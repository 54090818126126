import isEmpty from 'lodash/isEmpty';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { Form, IFormApi, IFormProps } from '@hotelplan/components.common.forms';
import {
  useOnSearchStateChanges,
  useSearchState,
} from '@hotelplan/fdr.lib.search.with-state';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FiltersSkeleton from 'components/domain/filters/Filters.skeleton';
import useFiltersSubmitController from 'components/domain/filters/useFiltersSubmitController';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { useFdrResetFiltersForm } from 'fdr/components/candidate/fdr-search/filters/use-fdr-reset-filters-form';
import { IFdrFiltersFormState } from 'fdr/components/candidate/fdr-search/types/filters.types';
import { IFdrFlightSRLState } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';

const FormWrapper = styled(Form)(
  sx2CssThemeFn({
    variant: 'forms.filtersForm',
    height: ['100%', 'auto'],
  })
) as React.FC<IFormProps<IFdrFiltersFormState>>;

interface IFdrFlightSrlFiltersFormProps {
  postSubmit?: () => void;
  formApiRef?: React.RefObject<IFormApi<IFdrFiltersFormState>>;
}

export function FdrFlightSrlFiltersForm(
  props: PropsWithChildren<IFdrFlightSrlFiltersFormProps>
): ReactElement {
  const { mobile } = useDeviceType();

  const defaultFormApiRef = useRef<IFormApi<IFdrFiltersFormState>>(null);
  const { formApiRef = defaultFormApiRef, postSubmit, children } = props;

  const {
    loading,
    state: { searchControl, filters },
    setState,
  } = useSearchState<IFdrFlightSRLState>();

  useEffect(() => {
    if (filters) {
      formApiRef?.current?.setValues(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnSearchStateChanges<IFdrFlightSRLState>(nextValues => {
    if (nextValues.filters) {
      formApiRef?.current?.setValues(nextValues.filters);
    }
  });

  useEffect(
    function resetFiltersOnSearchControlChange() {
      if (filters) {
        formApiRef?.current?.setValues(filters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchControl]
  );

  const onSubmit = (formState: IFdrFiltersFormState): void =>
    setState(
      prev => ({
        ...prev,
        filters: mobile ? { ...formState, prevChangedFilter: null } : formState,
      }),
      postSubmit
    );

  const { resetHandler, loading: defaultFiltersLoading } =
    useFdrResetFiltersForm(ESearchType.FLIGHT);

  if (loading || defaultFiltersLoading || isEmpty(filters))
    return <FiltersSkeleton />;

  return (
    <FormWrapper
      formApiRef={formApiRef}
      initialValues={filters}
      onSubmit={onSubmit}
      onReset={resetHandler}
    >
      {mobile ? (
        children
      ) : (
        <FiltersSubmitController postSubmit={postSubmit}>
          {children}
        </FiltersSubmitController>
      )}
    </FormWrapper>
  );
}

const FiltersSubmitController: React.FC<
  PropsWithChildren<IFdrFlightSrlFiltersFormProps>
> = ({ children, postSubmit }) => {
  const stateContext = useSearchState<IFdrFlightSRLState>();
  useFiltersSubmitController(stateContext, postSubmit);

  return <>{children}</>;
};
