import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrFlightPartition = styled.div.attrs({
  'data-id': 'flightPartition',
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    '.partition-title': {
      color: 'secondary',
      ...FONT_SIZE.S,
      mb: [3, 4],
      display: 'flex',
      alignItems: 'center',
      '&:after': {
        content: '""',
        display: 'block',
        flex: '1 auto',
        height: '1px',
        backgroundColor: 'secondary',
        ml: 3,
        mt: [null, 2],
      },
    },
  })
);

export const FdrFlightPartitionsWrapper = styled.div<{
  shouldShowPartitionHeadings: boolean;
}>(({ shouldShowPartitionHeadings }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: ['wrap', 'nowrap'],
    mb: shouldShowPartitionHeadings ? [null, 5] : {},
    '.airport-field, .flight-travel-dates-field': {
      flex: ['100%', '1', '1 1 405px'],
      mb: [2, 2, 0],
      '&:not(:last-child)': {
        mr: [0, '10px'],
      },
    },
    '.flight-travel-dates-field': {
      flex: ['100%', '1', '1 1 340px'],
    },
  })
);
