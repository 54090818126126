import addDays from 'date-fns/addDays';
import React, { ReactElement, useEffect } from 'react';
import { useField, useFormContext } from '@hotelplan/components.common.forms';
import { IFdrTravelDatesState } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { getTodaysDate } from '@hotelplan/libs.utils';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { IFdrFlightSrlControlState } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { prepareDatesInFdrFlightPartitions } from 'fdr/components/local/fdr-flight-search/flight-partitions/fdr-flight-partitions.utils';
import { useFdrFlightPartitions } from 'fdr/components/local/fdr-flight-search/flight-partitions/use-fdr-flight-partitions';
import {
  FdrFlightFormFieldTypes,
  useFdrFlightFormContext,
} from 'fdr/components/local/fdr-flight-search/use-fdr-flight-form-context';
import { FdrSearchTravelDates } from 'fdr/components/local/fdr-search-travel-dates';

interface IFdrFlightTravelDatesProps {
  partitionIndex: number;
  customLabel?: React.ReactNode;
}

export function FdrFlightTravelDates({
  partitionIndex,
  customLabel,
}: IFdrFlightTravelDatesProps): ReactElement {
  const { onFlightFormChange } = useFdrFlightFormContext();
  const { value: flightPartitions } = useFdrFlightPartitions();

  const [activeType] = useField<FdrFlightType | null>('flightType');

  const { values, onChange } = useFormContext<IFdrFlightSrlControlState>();

  // NOTE: The second partition date can not be earlier than first partition date.
  const secondPartitionMinDate = flightPartitions
    ? flightPartitions[0].travelDates?.departureDate
    : undefined;

  useEffect(() => {
    if (flightPartitions) {
      onChange({
        ...values,
        flightPartitions: prepareDatesInFdrFlightPartitions(flightPartitions),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FdrSearchTravelDates
      noVariants
      parentItemIndex={partitionIndex}
      parentItemName="flightPartitions"
      customConfiguration={{
        onlyExact: true,
        onlyDatesInfo: true,
        closeOnSelection: true,
        onlyDepartureDate: activeType !== FdrFlightType.Return,
        setReturnDateAsDeparture: activeType !== FdrFlightType.Return,
        maxDate: addDays(getTodaysDate(), 365),
        minDate: partitionIndex === 1 ? secondPartitionMinDate : undefined,
        flightActiveType: activeType,
      }}
      onChange={(travelDates: IFdrTravelDatesState | null) => {
        onFlightFormChange(
          travelDates,
          FdrFlightFormFieldTypes.TravelDatesField,
          partitionIndex
        );
      }}
      side="rightSide"
      customLabel={customLabel}
    />
  );
}
