import { useFormContext } from '@hotelplan/components.common.forms';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { IFdrFlightSrlControlState } from './fdr-flight-search.types';
import { mergeFdrFlightTypeToFdrFlightSearchState } from './flight-types/fdr-flight-types.mappers';

export enum FdrFlightFormFieldTypes {
  TravelDatesField = 'travelDatesField',
  FlightClassField = 'flightClassField',
  FlightTypeField = 'flightTypeField',
}

export function useFdrFlightFormContext() {
  const { values, onChange } = useFormContext<IFdrFlightSrlControlState>();

  const onFlightFormChange = (
    nextValue: any,
    type: FdrFlightFormFieldTypes,
    partitionIndex?: number
  ) => {
    switch (type) {
      case FdrFlightFormFieldTypes.FlightClassField: {
        const flightPartitions = values.flightPartitions?.map(partition => {
          return {
            ...partition,
            flightClass: nextValue,
          };
        });

        onChange({
          ...values,
          flightPartitions,
        });

        break;
      }
      case FdrFlightFormFieldTypes.FlightTypeField: {
        onChange(mergeFdrFlightTypeToFdrFlightSearchState(values, nextValue));

        break;
      }
      case FdrFlightFormFieldTypes.TravelDatesField: {
        const activeType = values.flightType;
        const flightPartitions = values.flightPartitions;

        if (!nextValue || !flightPartitions) break;

        const secondPartitionDate =
          flightPartitions[1].travelDates?.departureDate;

        // NOTE: If the departure date of the first partition was selected later than it is in the second partition,
        // then we need to update the departure date of the second partition.
        if (
          activeType === FdrFlightType.OpenJaw &&
          partitionIndex === 0 &&
          secondPartitionDate &&
          nextValue.departureDate > secondPartitionDate
        ) {
          onChange({
            ...values,
            flightPartitions: flightPartitions.map(partition => {
              return { ...partition, travelDates: nextValue };
            }),
          });
        }

        break;
      }
      default:
        break;
    }
  };

  return { values, onFlightFormChange };
}
