import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useFdrAutocompleteContext } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-context';
import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  FdrProductTravelType,
  FdrSearchHistoryType,
} from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  U_Extended_TravelType,
  EFdrFlightTravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { trackSelectDestination } from 'fdr/components/candidate/fdr-top-destination-autocomplete-suggestions/fdr-select-destination.tracking';
import { FdrTopDestinationAutocompleteSuggestions } from 'fdr/components/candidate/fdr-top-destination-autocomplete-suggestions/fdr-top-destination-autocomplete-suggestions';
import FdrTopDestinationItem from 'fdr/components/candidate/fdr-top-destination-autocomplete-suggestions/fdr-top-destination-item';
import useFdrTopDestinationAutocompleteIds from 'fdr/components/candidate/fdr-top-destination-autocomplete-suggestions/use-fdr-top-destination-autocomplete-ids';
import useFdrAddToHistory from 'fdr/components/local/fdr-history/useFdrAddToHistory';
import FdrSearchHistoryGroup from './fdr-search-history-group';
import FdrSearchHistorySkeleton from './fdr-search-history.skeleton';
import {
  SearchHistoryLinkItemWrapper,
  SearchHistoryLinkWrapper,
} from './fdr-search-history.styles';
import useSearchedProductHistory from './useSearchedProductHistory';

const SearchHistoryTopDestinationWrapper = styled.div(
  sx2CssThemeFn(
    {
      display: 'flex',
      '.historyGroup': {
        mb: 0,
        '> a': {
          mb: [2, 2],
        },
        '> a:first-child': {
          mt: [2, 2],
        },
        '.icon': {
          width: '24px',
          height: '24px',
          mr: '14px',
          mt: 2,
        },
        '.search-history-paginator': {
          display: 'none',
        },
      },
    },
    ({ theme }) => theme.autocomplete.tagBlock,
    {
      paddingTop: ['none', 'none'],
      border: ['none', 'none'],
      maxHeight: ['100%', '540px'],
    }
  )
);

const SuggestionListBox = styled.ul.attrs({
  role: `listbox`,
})(({ theme }) => theme.autocomplete.suggestionListStyles, {
  padding: 0,
  border: 'none',
});

const GroupHeader = styled.div(({ theme: { space, FONT_SIZE } }) =>
  sx2CssThemeFn({
    height: '40px',
    flexShrink: 0,
    paddingLeft: space[3],
    paddingRight: space[3],
    marginLeft: `-${space[3]}`,
    marginRight: `-${space[3]}`,
    display: 'flex',
    alignItems: 'center',
    ...FONT_SIZE.MEDIUM,
    backgroundColor: '#F4F4F4',
    '.icon': {
      width: '16px',
      height: '16px',
    },
  })
);

const GroupIcon = styled(Icon)({});

const GroupTitle = styled.span(({ theme: { space } }) => ({
  paddingLeft: space[2],
}));

interface ISearchHistoryAutocompleteSuggestions {
  travelType: U_Extended_TravelType;
  onHistoryChange?(shown: boolean): void;
  onTopDestinationsChange?(shown: boolean): void;
}

const HISTORY_RESULTS_PER_PAGE = 3;
const TOP_DESTINATION_MAX_PER_PAGE = 5;

const mapTravelTypeToSearchHistoryType = (
  travelType: U_Extended_TravelType
): FdrSearchHistoryType[] => {
  switch (travelType.fdr) {
    case EFdrFlightTravelType.Flight: {
      return [FdrSearchHistoryType.FlightSearch];
    }
    case FdrProductTravelType.HotelOnly: {
      return [FdrSearchHistoryType.HotelOnlySearch];
    }

    default:
      return [FdrSearchHistoryType.PackageSearch];
  }
};

const FdrSearchHistoryTopDestinationComboSuggestions: React.FC<ISearchHistoryAutocompleteSuggestions> =
  ({ travelType, onHistoryChange, onTopDestinationsChange }) => {
    const ctx = useFdrAutocompleteContext();
    const addHistoryItem = useFdrAddToHistory();
    const [t] = useTranslation('common');
    const [, { language }] = useTranslation();
    const pageType = usePageType();
    const isHomePage = pageType === EPageType.Home;

    const trackingCategory = 'history';

    const { items: historyItems, loading } = useSearchedProductHistory(
      mapTravelTypeToSearchHistoryType(travelType),
      HISTORY_RESULTS_PER_PAGE
    );

    const topDestinationIds = useFdrTopDestinationAutocompleteIds(travelType);

    const showHistorySuggestions =
      !ctx.loading &&
      ctx.suggestions.length === 0 &&
      ctx.value === '' &&
      !loading &&
      historyItems &&
      historyItems.length !== 0;

    const topDestinationFirstPageCount =
      TOP_DESTINATION_MAX_PER_PAGE - historyItems?.length || 0;

    const showTopDestinations =
      topDestinationIds.length !== 0 &&
      ctx.suggestions.length === 0 &&
      ctx.selectedItems.length === 0 &&
      topDestinationFirstPageCount > 0;

    const onHistoryItemClick = (searchState?: IFdrSrlControlState) => {
      if (searchState) {
        addHistoryItem(searchState);
      }
    };

    useEffect(() => {
      onTopDestinationsChange?.(showTopDestinations);
    }, [showTopDestinations]);

    useEffect(() => {
      onHistoryChange?.(showHistorySuggestions);
    }, [showHistorySuggestions]);

    return (
      <SearchHistoryTopDestinationWrapper>
        {loading && <FdrSearchHistorySkeleton />}
        {!loading && (
          <>
            {showTopDestinations && (
              <div>
                <FdrTopDestinationItem
                  items={[]}
                  name={t('allDestinations')}
                  key={`top-destination-all-destinations`}
                />
              </div>
            )}
            {showHistorySuggestions && (
              <>
                <GroupHeader>
                  <GroupIcon name={'history'} />
                  <GroupTitle>{t('recentSearch.heading')}</GroupTitle>
                </GroupHeader>
                <SuggestionListBox>
                  <FdrSearchHistoryGroup>
                    {historyItems.map((item, i) => {
                      if (!item) return null;

                      return (
                        <FdrRoute
                          link={item.link}
                          key={JSON.stringify({ i, item })}
                        >
                          <SearchHistoryLinkWrapper
                            onClick={() => {
                              trackSelectDestination(
                                item.title,
                                isHomePage ? 'home' : 'srl',
                                trackingCategory,
                                historyItems.indexOf(item) + 1,
                                language
                              );
                              onHistoryItemClick && onHistoryItemClick();
                            }}
                          >
                            <SearchHistoryLinkItemWrapper>
                              <div>{item.title}</div>
                              <div className="subtitle">{item.subtitle}</div>
                            </SearchHistoryLinkItemWrapper>
                          </SearchHistoryLinkWrapper>
                        </FdrRoute>
                      );
                    })}
                  </FdrSearchHistoryGroup>
                </SuggestionListBox>
              </>
            )}
            {showTopDestinations && (
              <>
                <GroupHeader>
                  <GroupIcon name={'planet'} />
                  <GroupTitle>{t('topDestinations')}</GroupTitle>
                </GroupHeader>
                <FdrTopDestinationAutocompleteSuggestions
                  travelType={travelType}
                  firstPageCount={topDestinationFirstPageCount}
                />
              </>
            )}
          </>
        )}
      </SearchHistoryTopDestinationWrapper>
    );
  };

export default FdrSearchHistoryTopDestinationComboSuggestions;
