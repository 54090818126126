import type { IAirport } from '@hotelplan/libs.tracking';
import { FdrAirportFragment } from 'fdr/schemas/fragment/regular/fdr-airport.generated';

export const mapFdrFlightAirportToTrackableFlightAirport = (
  airport: FdrAirportFragment
): IAirport => {
  return {
    code: airport.iata,
    label: airport.name || '',
  };
};
