import { DocumentNode } from 'graphql';
import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import { getNextPage } from '@hotelplan/components.common.autocomplete';
import { FdrAutocompleteDataSource } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-data-source';
import { IFdrDestinationAutocompleteVariables } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import {
  FlightAirportsEventType,
  trackFlightAirports,
} from '@hotelplan/libs.tracking';
import { IFdrDestinationData } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import {
  FdrAutocompleteItemFdrAirportSearchItemFragment,
  FdrAutocompleteItemFragment,
} from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';
import { FdrFlightAirportField } from './fdr-flight-airport-field';
import { mapFdrFlightAirportToTrackableFlightAirport } from './fdr-flight-airport.mappers';

const trackFlightAirportsSearchResult = (
  data: IFdrDestinationData | undefined,
  text: string
) => {
  const airports = data?.destinations.filter(Boolean) || [];
  const currentPage = data?.page?.page || 0;

  const nextPage = getNextPage(airports, {
    pageNumber: data?.page.page,
    resultsTotal: data?.page.total,
    resultsPerPage: data?.page.perPage,
  });

  const first = currentPage === 0;
  const hasMore = nextPage !== 0;

  trackFlightAirports({
    type: FlightAirportsEventType.FLIGHT_AIRPORTS_RESULTS,
    payload: {
      airports: airports.map(value => {
        if (value.__typename === 'FdrAirportSearchItem') {
          return mapFdrFlightAirportToTrackableFlightAirport(value.airport);
        }
      }),
      first,
      hasMore,
      text,
    },
  });
};

interface IFdrFlightGenericAirportFieldProps<TData, TVariables> {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  parentItemIndex?: number;
  parentItemName?: string;
  queryDocument: DocumentNode;
  dataMapper: (data: TData | undefined) => IFdrDestinationData | undefined;
  variablesMapper: (
    commonVars: IFdrDestinationAutocompleteVariables
  ) => TVariables;
  customLabel?: React.ReactNode;
}

export function FdrFlightGenericAirportField<
  TData extends object,
  TVariables extends { text: string }
>({
  name,
  label,
  placeholder,
  parentItemIndex,
  parentItemName,
  disabled,
  queryDocument,
  dataMapper,
  variablesMapper,
  customLabel,
}: PropsWithChildren<
  IFdrFlightGenericAirportFieldProps<TData, TVariables>
>): ReactElement {
  const suggestionMapper = useCallback(
    (
      destination: FdrAutocompleteItemFragment
    ): FdrAutocompleteItemFdrAirportSearchItemFragment['airport'] => {
      if (!destination) return null;

      if (destination.__typename === 'FdrAirportSearchItem') {
        return destination.airport;
      }
    },
    []
  );

  return (
    <FdrFlightAirportField
      name={name}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      parentItemIndex={parentItemIndex}
      parentItemName={parentItemName}
      customLabel={customLabel}
    >
      <FdrAutocompleteDataSource<
        FdrAutocompleteItemFdrAirportSearchItemFragment['airport'],
        TData,
        TVariables,
        FdrAutocompleteItemFragment
      >
        queryDocument={queryDocument}
        dataMapper={data => {
          return data
            ? {
                items: (dataMapper(data) as IFdrDestinationData).destinations,
                pagination: (dataMapper(data) as IFdrDestinationData).page,
              }
            : undefined;
        }}
        variablesMapper={variables => {
          return variablesMapper({
            selected: variables.selected.map(({ iata }) => iata),
            text: variables.query,
            page: variables.page,
          });
        }}
        suggestionMapper={suggestionMapper}
        onCompleted={(completedData, currentVariables) => {
          trackFlightAirportsSearchResult(
            dataMapper(completedData),
            currentVariables.text
          );
        }}
      />
    </FdrFlightAirportField>
  );
}
