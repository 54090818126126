import { useField } from '@hotelplan/components.common.forms';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { FdrFlightPartition } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { areFdrPartitionsValid } from './fdr-flight-partitions.utils';

interface IFdrFlightPartitions {
  value: Array<FdrFlightPartition> | null;
  areValid: boolean;
}

export function useFdrFlightPartitions(): IFdrFlightPartitions {
  const [activeType] = useField<FdrFlightType | null>('flightType');

  const [flightPartitions] =
    useField<Array<FdrFlightPartition> | null>('flightPartitions');

  return {
    value: flightPartitions,
    areValid: areFdrPartitionsValid(flightPartitions, activeType),
  };
}
