import { parse } from 'date-fns';
import { TFunction } from 'next-i18next';
import {
  getShortInformation as formatTravelRooms,
  mapSearchControlTravelRoomsToFormTravelRooms,
} from '@hotelplan/components.common.travel-rooms';
import { DATE_FORMAT } from '@hotelplan/fdr.lib.search.state-config';
import { getShortInformation as formatTravelDates } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { FdrFlightType, FdrProductTravelType } from '@hotelplan/supergraph-api';
import {
  isFdrHistoryVisitedProductCruise,
  isFdrHistoryVisitedProductHotel,
  isFdrHistoryVisitedProductRoundTrip,
  TFdrHistoryVisitedProduct,
  TFdrProductSearchHistory,
} from 'fdr/components/local/fdr-history/fdr-history.types';
import {
  FdrFlightSearchHistoryFragment,
  FdrProductSearchHistoryFragment,
} from 'fdr/schemas/query/history/fdr-search-history.generated';
import { FdrHistoryVisitedProductFragment } from 'fdr/schemas/query/history/fdr-visit-history.generated';
import { mapFdrHistoryFlightsToFlightPartitions } from './fdr-search-history.mappers';

interface ITravelTypeToIconName {
  [key: string]: string | undefined;
}

export const DEFAULT_ICON_NAME = 'history';
export const FLIGHT_ICON_NAME = 'flug';
export const TRAVEL_TYPE_TO_ICON_NAME: ITravelTypeToIconName = {
  HOTEL_ONLY: 'hotel',
  PACKAGE: 'flughotel-vertical',
  FdrHotel: 'hotel',
  FdrRoundTrip: 'rundreisen',
  FdrCruise: 'cruise',
  FdrHotel_PACKAGE: 'flughotel-vertical',
  FdrRoundTrip_PACKAGE: 'flugroundtrip-vertical',
  FdrCruise_PACKAGE: 'flugcruise-vertical',
} as const;

export const getFormattedLocationFromDestination = (
  object: TFdrHistoryVisitedProduct<FdrHistoryVisitedProductFragment>
): string => {
  if (!object?.product) return '';

  const locationParts: string[] = [];

  if (isFdrHistoryVisitedProductHotel(object.product)) {
    locationParts.push(object.product.resort.destination.country.name);
    locationParts.push(object.product.resort.destination.name);
  }

  if (
    isFdrHistoryVisitedProductCruise(object.product) ||
    isFdrHistoryVisitedProductRoundTrip(object.product)
  ) {
    locationParts.push(object.product.destination.country.name);
    locationParts.push(object.product.destination.name);
  }

  return locationParts.join(', ');
};

export const getIconName = (
  travelType?: FdrProductTravelType,
  productType?: string
): string => {
  if (productType) {
    if (travelType === FdrProductTravelType.Package) {
      return (
        TRAVEL_TYPE_TO_ICON_NAME[`${productType}_${travelType}`] ||
        DEFAULT_ICON_NAME
      );
    }

    return TRAVEL_TYPE_TO_ICON_NAME[productType] || DEFAULT_ICON_NAME;
  }

  if (travelType) {
    return TRAVEL_TYPE_TO_ICON_NAME[travelType] || DEFAULT_ICON_NAME;
  }

  return DEFAULT_ICON_NAME;
};

export const getTitleFromProductSearchHistoryItem = (
  object: FdrProductSearchHistoryFragment,
  t: TFunction
): string => {
  if (!object?.productSearchCriteria) return '';

  const formattedTravelType = t(
    object.productSearchCriteria.travelType === FdrProductTravelType.HotelOnly
      ? 'common:hotel'
      : 'common:package.plus'
  );

  const destinationArray = [
    ...(object.productSearchCriteria?.destinations?.products?.map(
      product => product.name
    ) || []),
    ...(object.productSearchCriteria?.destinations?.geo?.map(geo => geo.name) ||
      []),
  ];

  const formattedDestinationsArray = destinationArray.slice(0, 2);

  if (formattedDestinationsArray?.[1]) {
    formattedDestinationsArray[1] = '...';
  }

  return `${
    formattedDestinationsArray.join(', ') ||
    t('common:history.searches.noDestinations')
  }, ${formattedTravelType}`;
};

export const getSubTitleFromProductSearchHistoryItem = (
  object: TFdrProductSearchHistory<FdrProductSearchHistoryFragment>,
  t: TFunction
): string => {
  const travellers = mapSearchControlTravelRoomsToFormTravelRooms(
    { ...object.productSearchCriteria.travellers, __typename: 'Travellers' },
    object.productSearchCriteria.rooms
  );

  const formattedTravellers = formatTravelRooms(t, travellers);

  const formattedTravelDates = formatTravelDates(t, {
    departureDate: parse(
      object.productSearchCriteria.period.departureDate,
      DATE_FORMAT,
      new Date()
    ),
    returnDate: parse(
      object.productSearchCriteria.period.returnDate,
      DATE_FORMAT,
      new Date()
    ),
    durationData: {
      type: 'night',
      duration: object.productSearchCriteria.period.durations ?? [],
    },
    showDuration: true,
  }).travelDates;

  return `${formattedTravelDates}, ${formattedTravellers}`;
};

export function getTitleFromFlightSearchHistoryItem(
  object: FdrFlightSearchHistoryFragment,
  t: TFunction
): string {
  if (!object) return '';

  const { flightSearchCriteria } = object;

  const formattedTravelType = t('common:flight');

  const firstPartitionDepartureAirport = flightSearchCriteria.flights
    ? flightSearchCriteria.flights[0].departureAirport?.name
    : '';

  const firstPartitionArrivalAirport = flightSearchCriteria.flights
    ? flightSearchCriteria.flights[0].arrivalAirport?.name
    : '';

  let formattedAirports = `${firstPartitionDepartureAirport} - ${firstPartitionArrivalAirport}`;

  if (flightSearchCriteria.flightType === FdrFlightType.OpenJaw) {
    const secondPartitionDepartureAirport = flightSearchCriteria.flights
      ? flightSearchCriteria.flights[1].departureAirport?.name
      : '';

    const secondPartitionArrivalAirport = flightSearchCriteria.flights
      ? flightSearchCriteria.flights[1].arrivalAirport?.name
      : '';

    formattedAirports = `${formattedAirports}${
      secondPartitionDepartureAirport === firstPartitionArrivalAirport
        ? ` - ${secondPartitionArrivalAirport}`
        : ` / ${secondPartitionDepartureAirport} - ${secondPartitionArrivalAirport}`
    }`;
  }

  return `${formattedAirports}, ${formattedTravelType}`;
}

export function getSubTitleFromFlightSearchHistoryItem(
  object: FdrFlightSearchHistoryFragment,
  t: TFunction
) {
  if (!object) return '';

  const { flightSearchCriteria } = object;

  let formattedTravelDates = '';

  const flightPartitions = mapFdrHistoryFlightsToFlightPartitions(
    flightSearchCriteria.flights
  );

  // NOTE: If the flight type is not OpenJaw, then we have only one partition.
  if (flightSearchCriteria.flightType !== FdrFlightType.OpenJaw) {
    const travelDates = flightPartitions
      ? flightPartitions[0].travelDates
      : null;

    formattedTravelDates = travelDates
      ? formatTravelDates(t, {
          departureDate: travelDates.departureDate,
          returnDate: travelDates.returnDate,
          durationData: travelDates.duration,
          showDuration: false,
        }).travelDates
      : '';
  } else {
    const firstPartitionTravelDates = flightPartitions
      ? flightPartitions[0].travelDates
      : null;
    const secondPartitionTravelDates = flightPartitions
      ? flightPartitions[1].travelDates
      : null;

    if (!firstPartitionTravelDates || !secondPartitionTravelDates) {
      return '';
    }

    formattedTravelDates = formatTravelDates(t, {
      departureDate: firstPartitionTravelDates.departureDate,
      returnDate: secondPartitionTravelDates.returnDate,
      durationData: firstPartitionTravelDates.duration,
      showDuration: false,
    }).travelDates;
  }

  const travellers = mapSearchControlTravelRoomsToFormTravelRooms(
    {
      ...object.flightSearchCriteria.travellers,
      __typename: 'Travellers',
    },
    0
  );

  const formattedTravellers = formatTravelRooms(t, travellers);

  return `${formattedTravelDates}, ${formattedTravellers}`;
}
