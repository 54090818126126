import camelCase from 'lodash/camelCase';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { getShortInformation as formatTravelRooms } from '@hotelplan/components.common.travel-rooms';
import { useSearchState } from '@hotelplan/fdr.lib.search.with-state';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import CurrentSearch from 'components/domain/currentSearch/CurrentSearch';
import CurrentSearchSkeleton from 'components/domain/currentSearch/CurrentSearch.skeleton';
import { IFdrFlightSRLState } from './fdr-flight-search.types';
import { getFdrPartitionShortInformation } from './flight-partitions/fdr-flight-partitions.utils';

type FdrCurrentSearchItemType = {
  testId?: string;
  content: ReactNode;
};

interface IFdrFlightCurrentSearchFieldProps {
  handleEdit: () => void;
}

export function FdrFlightCurrentSearchField({
  handleEdit,
}: IFdrFlightCurrentSearchFieldProps): ReactElement {
  const [t] = useTranslation('common');

  const {
    loading,
    state: { searchControl },
  } = useSearchState<IFdrFlightSRLState>();

  const currentSearchItems = useMemo(() => {
    if (!searchControl) return [];

    const {
      flightPartitions = null,
      travelRooms = null,
      flightType = null,
    } = searchControl;

    const isReturnFlight = flightType === FdrFlightType.Return;

    const partitionsToRender =
      flightType === FdrFlightType.OpenJaw
        ? flightPartitions
        : flightPartitions?.slice(0, 1);

    const formattedTravelRooms =
      travelRooms && formatTravelRooms(t, travelRooms);

    const formattedFlightClass =
      partitionsToRender &&
      t(`flightClass.${camelCase(partitionsToRender[0].flightClass || '')}`);

    const formattedItems = [] as FdrCurrentSearchItemType[];

    partitionsToRender?.map((partition, i) => {
      formattedItems.push({
        testId: `airportsFlight${i}`,
        content: getFdrPartitionShortInformation(partition, isReturnFlight)
          .airports,
      });
      formattedItems.push({
        testId: `travelDatesFlight${i}`,
        content: getFdrPartitionShortInformation(partition, isReturnFlight)
          .travelDates,
      });
    });

    return [
      ...formattedItems,
      {
        testId: 'travelRoomsAndFlightClass',
        content: `${formattedTravelRooms}, ${formattedFlightClass}`,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchControl]) as FdrCurrentSearchItemType[];

  if (loading || !searchControl) return <CurrentSearchSkeleton />;

  return <CurrentSearch items={currentSearchItems} handleEdit={handleEdit} />;
}
