import React, { PropsWithChildren, ReactElement, useRef } from 'react';
import { Form, IFormApi } from '@hotelplan/components.common.forms';
import { useGSSContext } from '@hotelplan/fdr.lib.search.with-global-state';
import {
  useOnSearchStateChanges,
  useSearchState,
} from '@hotelplan/fdr.lib.search.with-state';
import CurrentSearchSkeleton from 'components/domain/currentSearch/CurrentSearch.skeleton';
import { useCurrentSearchContext } from 'components/domain/currentSearch/CurrentSearchContext';
import { useFilterCountErrorsContext } from 'components/domain/filters/FilterCountErrorsContext';
import { fdrMapFlightSRLSearchValuesQueryToFlightSRLState } from 'fdr/components/local/fdr-flight-search/fdr-flight-search.mappers';
import {
  IFdrFlightSRLState,
  TFdrFlightSrlControlStateWithType,
} from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import { useFdrFlightSearchTimestamps } from 'fdr/components/local/fdr-flight-search/use-fdr-flight-srl-timestamps';
import useFdrAddToHistory from 'fdr/components/local/fdr-history/useFdrAddToHistory';
import { useFdrSearchFormRerender } from 'fdr/components/local/fdr-search-form/use-fdr-search-form-rerender';
import { useFdrFlightSearchControlsQuery } from 'fdr/schemas/query/flight-search/form/fdr-flight-search-controls.generated';

interface IFdrFlightSearchFormProps {
  postSubmit?: (state: IFdrFlightSRLState) => void;
}

export function FdrFlightSearchForm({
  postSubmit,
  children,
}: PropsWithChildren<IFdrFlightSearchFormProps>): ReactElement {
  const { saveWithErrors } = useFilterCountErrorsContext();

  const { setFGSS } = useGSSContext<IFdrFlightSRLState>();

  const { closeSearchControl, formRef } = useCurrentSearchContext();
  const { setFlightSearchRequestTimestamp } = useFdrFlightSearchTimestamps();

  const formApiRef = useRef<IFormApi<TFdrFlightSrlControlStateWithType>>(null);

  const {
    data: defaultSearchControlValues,
    loading: defaultSearchControlLoading,
  } = useFdrFlightSearchControlsQuery();

  const {
    loading,
    state: { searchControl },
    setState,
  } = useSearchState<IFdrFlightSRLState>();

  const addHistoryItem = useFdrAddToHistory();
  const formUpdateKey = useFdrSearchFormRerender();

  useOnSearchStateChanges<IFdrFlightSRLState>(nextValues => {
    formApiRef.current?.setValues(
      nextValues.searchControl as TFdrFlightSrlControlStateWithType
    );
  });

  if ((loading || defaultSearchControlLoading) && !searchControl)
    return <CurrentSearchSkeleton />;

  return (
    searchControl && (
      <Form<TFdrFlightSrlControlStateWithType>
        key={formUpdateKey}
        formRef={formRef}
        formApiRef={formApiRef}
        initialValues={searchControl}
        onSubmit={(nextModel): void => {
          setState(
            prev => ({
              ...prev,
              searchControl: nextModel,
              filters: { ...prev.filters, prevChangedFilter: null },
            }),
            postSubmit
          );
          saveWithErrors(false);
          setFGSS(
            defaultSearchControlValues
              ? fdrMapFlightSRLSearchValuesQueryToFlightSRLState(
                  defaultSearchControlValues
                )
              : {},
            nextModel
          );
          addHistoryItem({ ...nextModel, type: 'FLIGHT' });
          setFlightSearchRequestTimestamp();
          closeSearchControl();
        }}
      >
        {children}
      </Form>
    )
  );
}
