import intersection from 'lodash/intersection';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IFdrAutocompleteCountStateItem,
  useFdrAutocompleteCountContext,
} from '@hotelplan/fdr.regular.basis.fdr-autocomplete-count-context';
import { useFdrAutocompleteContext } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-context';
import { FdrAutocompleteOptionLoader } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-option';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import { mapGeoObjectIdAndNameToTravelDestination } from './fdr-top-destination.utils';

interface ITopDestinationItem {
  items: { id: string; name: string }[];
  name: string;
}

const TopDestinationLink = styled.a(({ theme: { space } }) => ({
  display: 'flex',
  padding: `${space[2]} 0`,
  justifyItems: 'center',
}));

const TopDestinationLinkTitle = styled.span({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flexGrow: 1,
});

const TopDestinationLinkCount = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const FdrTopDestinationItem: React.FC<ITopDestinationItem> = ({
  items,
  name,
}) => {
  const travelDestinations = items.map(item =>
    mapGeoObjectIdAndNameToTravelDestination(item.id, item.name)
  );
  const autocompleteContext = useFdrAutocompleteContext<TFdrFlatDestination>();
  const autocompleteCountContext =
    useFdrAutocompleteCountContext<TFdrFlatDestination>();
  const [countItem, setCountItem] =
    useState<IFdrAutocompleteCountStateItem<TFdrFlatDestination>>(null);
  const [t] = useTranslation('common');

  useEffect(() => {
    const item = autocompleteCountContext.items.find(contextItem => {
      return (
        contextItem.items.length === items.length &&
        intersection(
          contextItem.items.map(i => i.id),
          items.map(i => i.id)
        ).length === items.length
      );
    });

    if (countItem === null && !item) {
      autocompleteCountContext.putItem({
        id: items.map(i => i.id).join(';'),
        items: travelDestinations,
      });
    } else if (item) {
      setCountItem(item);
    }
  }, [autocompleteCountContext.items, countItem]);

  if (!travelDestinations.filter(i => i === null).length) {
    return (
      <TopDestinationLink
        href={'#'}
        onClick={e => {
          e.preventDefault();

          if (travelDestinations.length) {
            travelDestinations.forEach(i => {
              autocompleteContext.selectItem(i);
            });
          } else {
            autocompleteContext.selectItem({
              name: t('allDestinations'),
              canonicalName: t('allDestinations'),
              __typename: 'FdrDestination',
            } as TFdrFlatDestination);
          }
        }}
      >
        <TopDestinationLinkTitle>{name}</TopDestinationLinkTitle>
        <TopDestinationLinkCount>
          {countItem?.loading && <FdrAutocompleteOptionLoader />}
          {!countItem?.loading &&
            typeof countItem?.count === 'number' &&
            countItem?.count}
        </TopDestinationLinkCount>
      </TopDestinationLink>
    );
  }

  return null;
};

export default FdrTopDestinationItem;
