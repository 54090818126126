type TTypedObject = {
  __typename?: string;
};

export type TFdrHistoryVisitedProduct<T extends TTypedObject> = Extract<
  T,
  { __typename: 'FdrHistoryVisitedProduct' }
>;

export const isFdrHistoryVisitedProduct = <T extends TTypedObject>(
  object: T
): object is TFdrHistoryVisitedProduct<T> => {
  return object?.__typename === 'FdrHistoryVisitedProduct';
};

export type TFdrHistoryVisitedProductHotel<T extends TTypedObject> = Extract<
  T,
  { __typename: 'FdrHotel' }
>;

export type TFdrHistoryVisitedProductCruise<T extends TTypedObject> = Extract<
  T,
  { __typename: 'FdrCruise' }
>;

export type TFdrHistoryVisitedProductRoundTrip<T extends TTypedObject> =
  Extract<T, { __typename: 'FdrRoundTrip' }>;

export const isFdrHistoryVisitedProductHotel = <T extends TTypedObject>(
  object: T
): object is TFdrHistoryVisitedProductHotel<T> => {
  return object?.__typename === 'FdrHotel';
};

export const isFdrHistoryVisitedProductCruise = <T extends TTypedObject>(
  object: T
): object is TFdrHistoryVisitedProductCruise<T> => {
  return object?.__typename === 'FdrCruise';
};

export const isFdrHistoryVisitedProductRoundTrip = <T extends TTypedObject>(
  object: T
): object is TFdrHistoryVisitedProductRoundTrip<T> => {
  return object?.__typename === 'FdrRoundTrip';
};

export type TFdrProductSearchHistory<T extends TTypedObject> = Extract<
  T,
  { __typename: 'FdrProductSearchHistory' }
>;

export const isFdrProductSearchHistory = <T extends TTypedObject>(
  object: T
): object is TFdrProductSearchHistory<T> => {
  return object?.__typename === 'FdrProductSearchHistory';
};

export type TFdrFlightSearchHistory<T extends TTypedObject> = Extract<
  T,
  { __typename: 'FdrFlightSearchHistory' }
>;

export const isFdrFlightSearchHistory = <T extends TTypedObject>(
  object: T
): object is TFdrFlightSearchHistory<T> => {
  return object?.__typename === 'FdrFlightSearchHistory';
};
