import styled from 'styled-components';
import { sx2CssTheme, sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { withActiveStyles } from '@hotelplan/components.common.mixins';

export const FdrFlightTypesWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: ['wrap', 'nowrap'],
    m: ['-13px', 0],
    mt: 0,
    alignItems: 'flex-start',
    '.label': {
      m: ['13px', 0],
      mr: [0, '20px'],
      mb: ['13px', 0],
      mt: 0,
    },
  })
);

export const FdrFlightTypeRadioInput = styled(RadioButton)(
  ({ theme: { colors } }) => ({
    '@media screen and (max-width: 370px)': {
      flex: '0 0 auto',
    },
    '&:focus-visible': {
      span: {
        outline: `auto 2px`,
        outlineOffset: '-1px',
        outlineColor: colors.defaultText,
      },
    },
    '.pills-radio-input': {
      '&:focus-visible': {
        span: {
          outline: `auto 2px`,
          outlineOffset: '-1px',
          outlineColor: colors.defaultText,
        },
      },
    },
  })
);

export const FdrTextWrapper = styled.span<{ checked: boolean }>(
  ({ checked, theme, theme: { colors } }) =>
    withActiveStyles(
      checked,
      sx2CssTheme(theme, {
        fontSize: [1, 2],
        px: ['10px', '27px'],
        py: [1, 2],
        border: `1px solid ${colors.primary}`,
        color: colors.primary,
        cursor: 'pointer',
        borderRadius: ['15px', '20px'],
        lineHeight: [null, '1.4'],
        '&:hover,&:focus': {
          color: colors.interactionPrimary,
          backgroundColor: colors.lightMint,
          borderColor: colors.interactionPrimary,
          outline: 'none',
        },
      }),
      {
        backgroundColor: colors.primary,
        color: colors.white,
        '&:hover': {
          color: colors.white,
          backgroundColor: colors.primary,
          borderColor: colors.primary,
          outline: 'none',
        },
        '&:focus': {
          color: colors.white,
          backgroundColor: colors.interactionPrimary,
          borderColor: colors.interactionPrimary,
        },
      }
    )
);
