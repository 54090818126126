import { DocumentNode } from '@apollo/client';
import { useQuery } from '@hotelplan/fdr.lib.apollo.query';
import { calculateNextPage } from '@hotelplan/libs.utils';
import { FdrPaginationFragment } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';

export const SEARCH_HISTORY_PER_PAGE = 5;

const defaultPage: FdrPaginationFragment = {
  page: 0,
  perPage: SEARCH_HISTORY_PER_PAGE,
  total: 0,
};

export function useSearchHistory<TData, TVariables, TItem>({
  documentNode,
  mapToVariables,
  mapToItems,
  mapToPage,
}: {
  documentNode: DocumentNode;
  mapToVariables(page: number): TVariables;
  mapToItems(data: TData): TItem[];
  mapToPage(data: TData): FdrPaginationFragment;
}) {
  const { data, fetchMore, loading } = useQuery<TData, TVariables>(
    documentNode,
    { variables: mapToVariables(0), notifyOnNetworkStatusChange: true }
  );

  const items = data ? mapToItems(data) : [];
  const { page, perPage, total } = data ? mapToPage(data) : defaultPage;

  const nextPage = calculateNextPage(page, perPage, total);

  function onNextPage() {
    fetchMore({
      variables: mapToVariables(nextPage),
    });
  }

  return {
    items,
    onNextPage,
    nextPage,
    loading,
  };
}
