import { TFunction, useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { FdrFlightClass as FdrFlightClassType } from '@hotelplan/supergraph-api';
import {
  FdrFlightFormFieldTypes,
  useFdrFlightFormContext,
} from 'fdr/components/local/fdr-flight-search/use-fdr-flight-form-context';
import { FdrCabinClassInput } from './fdr-flight-class.styles';

const getFlightClassOptions = (
  t: TFunction
): Array<{ value: FdrFlightClassType; label: string }> => [
  {
    value: FdrFlightClassType.Economy,
    label: t('forms:economyFlightClass.selectInput'),
  },
  {
    value: FdrFlightClassType.PremiumEconomy,
    label: t('forms:premiumEconomyFlightClass.selectInput'),
  },
  {
    value: FdrFlightClassType.Business,
    label: t('forms:businessFlightClass.selectInput'),
  },
  {
    value: FdrFlightClassType.First,
    label: t('forms:firstFlightClass.selectInput'),
  },
];

interface IFdrFlightClassProps {
  className?: string;
  customLabel?: React.ReactNode;
}

export function FdrFlightClass({
  customLabel,
  className,
}: IFdrFlightClassProps): ReactElement {
  const [t] = useTranslation('forms');
  const { values, onFlightFormChange } = useFdrFlightFormContext();

  const flightClass = values?.flightPartitions
    ? values.flightPartitions[0].flightClass
    : FdrFlightClassType.Economy;

  return (
    <FdrCabinClassInput
      icon={{ name: 'cabin' }}
      data-id="cabin-class"
      tabIndex={0}
      hideLabel
      value={flightClass || FdrFlightClassType.Economy}
      options={getFlightClassOptions(t)}
      label={t('forms:flightClass.label')}
      className={className}
      onChange={(value): void => {
        onFlightFormChange(value, FdrFlightFormFieldTypes.FlightClassField);
      }}
      customLabel={customLabel}
    />
  );
}
