import styled from 'styled-components';
import Container from 'components/domain/container/Container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrFlightSRLSearchFormWrapper = styled.div(
  ({ theme: { colors } }) => ({
    backgroundColor: colors.bgLightGrey,
  })
);

export const FdrFlightSearchControlWrapper = styled(Container)(
  sx2CssThemeFn({
    boxShadow: ['homeSearchFormWrap', 'none'],
    '.searchControlFormLayoutWrapper': {
      px: [0, 0],
    },
  })
);
