import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const SearchHistorySkeletonTitle = styled.h3(
  sx2CssThemeFn({
    fontSize: ['18px', 3],
  })
);

const FdrSearchHistorySkeleton: React.FC = () => {
  const [t] = useTranslation('common');

  return (
    <div data-id="search-history-skeleton">
      <SearchHistorySkeletonTitle>{t('loading')}</SearchHistorySkeletonTitle>
    </div>
  );
};

export default FdrSearchHistorySkeleton;
