import type { TFunction } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useCurrentSearchContext } from 'components/domain/currentSearch/CurrentSearchContext';
import CustomLabel from 'components/domain/CustomLabel';
import { useFdrFourTravelRooms } from 'fdr/components/candidate/fdr-search-control/travel-rooms/use-fdr-four-travel-rooms';
import { FdrSRLFlightAirportField } from 'fdr/components/local/fdr-flight-search/flight-airport/fdr-srl-flight-airport-field';
import { useFdrFlightPartitions } from 'fdr/components/local/fdr-flight-search/flight-partitions/use-fdr-flight-partitions';
import { FdrFlightFormLayout } from './fdr-flight-form-layout';
import { FdrSearchControlFormLayoutWrapper } from './fdr-flight-form.styles';

const renderDepartureAirportField = (
  t: TFunction,
  partitionIndex: number
): React.ReactNode => {
  return (
    <FdrSRLFlightAirportField
      name="departureAirport"
      parentItemIndex={partitionIndex}
      label={t('forms:departureAirport.placeholder')}
      placeholder={t('forms:departureAirport.placeholder')}
      customLabel={
        <CustomLabel htmlFor="departureAirport">
          {t('forms:departureAirport.label')}
        </CustomLabel>
      }
    />
  );
};

const renderArrivalAirportField = (
  t: TFunction,
  partitionIndex: number
): React.ReactNode => {
  return (
    <FdrSRLFlightAirportField
      name="arrivalAirport"
      parentItemIndex={partitionIndex}
      label={t('forms:arrivalAirport.placeholder')}
      placeholder={t('forms:arrivalAirport.placeholder')}
      customLabel={
        <CustomLabel htmlFor="arrivalAirport">
          {t('forms:arrivalAirport.label')}
        </CustomLabel>
      }
    />
  );
};

interface IFdrFlightFormContainerProps {
  handleCancel?: () => void;
  withCustomLabels?: boolean;
}

export function FdrFlightFormContainer({
  withCustomLabels,
  handleCancel,
}: IFdrFlightFormContainerProps): ReactElement {
  const { ref } = useCurrentSearchContext();
  const { isValid: isTravelRoomsValid, isEmpty } = useFdrFourTravelRooms();
  const { areValid: areFlightPartitionsValid } = useFdrFlightPartitions();

  return (
    <div ref={ref}>
      <FdrSearchControlFormLayoutWrapper>
        <FdrFlightFormLayout
          renderArrivalAirportField={renderArrivalAirportField}
          renderDepartureAirportField={renderDepartureAirportField}
          isSubmitAllowed={
            areFlightPartitionsValid && isTravelRoomsValid && !isEmpty
          }
          handleCancel={handleCancel}
          withCustomLabels={withCustomLabels}
        />
      </FdrSearchControlFormLayoutWrapper>
    </div>
  );
}
