import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const TopDestinationShowMoreLink = styled.a(
  ({ theme: { space, colors, FONT_SIZE } }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.LINK_LARGE,
      display: 'block',
      padding: `${space[2]} 0`,
      textDecoration: 'underline',
      textDecorationColor: colors.linkUnderlineColor,
      textUnderlineOffset: '6px',
      color: colors.defaultText,
      transition: 'none',
      transform: 'translate(0)',
      '.icon': {
        width: space[3],
        height: space[3],
        marginLeft: space[2],
        verticalAlign: 'middle',
      },
      '&:hover,&:focus': {
        color: colors.defaultText,
        textDecorationColor: colors.defaultText,
        transform: 'scale(1)',
      },
    })
);
