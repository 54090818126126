import React, { ReactElement } from 'react';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { searchTypeMapper } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.util';
import {
  FdrTextSearchAutocompleteDocument,
  FdrTextSearchAutocompleteQuery,
  FdrTextSearchAutocompleteQueryVariables,
} from 'fdr/schemas/query/search/autocomplete/fdr-text-search-autocomplete.generated';
import { IFdrFlightAirportFieldProps } from './fdr-flight-airport-field.types';
import { FdrFlightGenericAirportField } from './fdr-flight-generic-airport-field';

export function FdrSRLFlightAirportField({
  name,
  label,
  placeholder,
  customLabel,
  parentItemIndex,
  disabled,
}: IFdrFlightAirportFieldProps): ReactElement {
  return (
    <FdrFlightGenericAirportField<
      FdrTextSearchAutocompleteQuery,
      FdrTextSearchAutocompleteQueryVariables
    >
      name={name}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      parentItemIndex={parentItemIndex}
      parentItemName="flightPartitions"
      queryDocument={FdrTextSearchAutocompleteDocument}
      dataMapper={(data: FdrTextSearchAutocompleteQuery) => {
        if (!data) return null;

        return {
          page: data.fdrTextAutocomplete.pagination,
          destinations: data.fdrTextAutocomplete.itemList,
        };
      }}
      variablesMapper={variables => {
        return {
          ...variables,
          searchType: searchTypeMapper(ESearchType.FLIGHT),
        };
      }}
      customLabel={customLabel}
    />
  );
}
