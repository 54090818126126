import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { useArrayNestedField } from '@hotelplan/components.common.forms';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { FdrHighlightText } from '@hotelplan/fdr.regular.basis.fdr-highlight';
import { FdrAutocompleteOption } from '@hotelplan/fdr.regular.fusion.fdr-autocomplete-option';
import { EFdrFlightTravelType } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import FdrSearchHistoryTopDestinationComboSuggestions from 'fdr/components/local/fdr-search-history/fdr-search-history-top-destination-combo-suggestions';
import { FdrAirportFragment } from 'fdr/schemas/fragment/regular/fdr-airport.generated';
import {
  FdrFlightAirportComboboxInput,
  FdrFlightAirportFakeInput,
} from './fdr-flight-airport-field.styles';

const renderDestinationLabel = (label: React.ReactNode, isGroup?: boolean) => (
  <FdrAutocompleteOption
    icon={<BsIcon name={isGroup ? 'airport' : 'flug'} />}
    label={label}
  />
);

const renderDestinationSuggestionLabel = (
  item: FdrAirportFragment,
  query: string
): React.ReactNode =>
  renderDestinationLabel(
    <FdrHighlightText text={item.name || ''} substring={query} />,
    item.isGroup
  );

interface IFdrFlightAirportFieldProps {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  parentItemIndex?: number;
  parentItemName?: string;
  minQueryLength?: number;
  className?: string;
  customLabel?: React.ReactNode;
}

export function FdrFlightAirportField({
  name,
  parentItemName,
  parentItemIndex,
  className,
  customLabel,
  label,
  placeholder,
  disabled,
  children,
}: PropsWithChildren<IFdrFlightAirportFieldProps>): ReactElement {
  const [t] = useTranslation('forms');

  const [airport, setAirport] = useArrayNestedField<FdrAirportFragment | null>(
    name,
    parentItemName,
    parentItemIndex
  );

  if (disabled) {
    return (
      <FdrFlightAirportFakeInput
        data-id="flight-airport-fake-input"
        icon={name === 'departureAirport' ? 'flug' : 'flug-arrival'}
        placeholder={t(`${name}.placeholder`)}
        value={airport?.name || ''}
        className={className}
        customLabel={customLabel}
      />
    );
  }

  return (
    <FdrFlightAirportComboboxInput
      noMobileControls
      name={name}
      label={label}
      placeholder={placeholder}
      icon={{ name: name === 'departureAirport' ? 'flug' : 'flug-arrival' }}
      value={airport ? [airport] : []}
      onChange={(nextItems): void => {
        setAirport(nextItems[0]);
      }}
      onOptionSelect={(suggestion, text, popupControl) => {
        popupControl.apply();
        // trackFlightAirportsSelect(
        //   mapCanonicalFlightAirportToTrackableFlightAirport(suggestion),
        //   text
        // );
      }}
      renderSuggestion={renderDestinationSuggestionLabel}
      renderTagLabel={(item): React.ReactNode =>
        renderDestinationLabel(item.name, item.isGroup)
      }
      getTagName={(item): string => item.name || ''}
      displayValue={airport?.name || ''}
      maxItems={1}
      className={className}
      customLabel={customLabel}
    >
      {children}
      <FdrSearchHistoryTopDestinationComboSuggestions
        travelType={{
          fdr: EFdrFlightTravelType.Flight,
        }}
      />
    </FdrFlightAirportComboboxInput>
  );
}
