import { TFunction, useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { useField } from '@hotelplan/components.common.forms';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import CustomLabel from 'components/domain/CustomLabel';
import FdrTravelRoomsFieldContainer from 'fdr/components/candidate/fdr-search-control/travel-rooms/fdr-travel-rooms-field-container';
import { FdrFlightClass } from 'fdr/components/local/fdr-flight-search/flight-class/fdr-flight-class';
import { FdrFlightPartitions } from 'fdr/components/local/fdr-flight-search/flight-partitions/fdr-flight-partitions';
import { FdrFlightTypes } from 'fdr/components/local/fdr-flight-search/flight-types/fdr-flight-types';
import {
  FdrCloseButton,
  FdrFlightClassFieldWrapper,
  FdrFlightFormFooterOptions,
  FdrFlightFormFooterToggleOptionsButtonWrapper,
  FdrFlightFormFooterWrapper,
  FdrFlightFormLayoutWrapper,
  FdrFlightParamSubmitButton,
  FdrFlightParamSubmitWrapper,
  FdrFlightParamsWrapper,
  FdrSubmitButton,
} from './fdr-flight-form.styles';

interface IFdrFlightFormLayoutProps {
  renderDepartureAirportField: (
    t: TFunction,
    partitionId: number
  ) => React.ReactNode;
  renderArrivalAirportField: (
    t: TFunction,
    partitionId: number
  ) => React.ReactNode;
  isSubmitAllowed?: boolean;
  handleCancel?: () => void;
  className?: string;
  withCustomLabels?: boolean;
}

export function FdrFlightFormLayout({
  renderDepartureAirportField,
  renderArrivalAirportField,
  isSubmitAllowed,
  withCustomLabels,
  handleCancel,
  className,
}: IFdrFlightFormLayoutProps): ReactElement {
  const { mobile } = useDeviceType();
  const [t] = useTranslation(['common', 'forms']);

  const [activeType] = useField<FdrFlightType | null>('flightType');
  const [isOptionsOpen, , , toggleOptions] = useToggleState(false);

  return (
    <FdrFlightFormLayoutWrapper className={className}>
      <div className="flight-types-wrapper">
        <FdrFlightTypes />
      </div>
      <FdrFlightPartitions
        renderDepartureAirportField={renderDepartureAirportField}
        renderArrivalAirportField={renderArrivalAirportField}
        withCustomLabels={withCustomLabels}
      />
      <FdrFlightParamsWrapper activeType={activeType}>
        <div className="flight-param">
          <FdrTravelRoomsFieldContainer
            side="leftSide"
            customLabel={
              withCustomLabels ? (
                <CustomLabel htmlFor={`travelers`}>
                  {t('forms:flightTravelers.label')}
                </CustomLabel>
              ) : null
            }
          />
        </div>
        {!mobile && (
          <div className="flight-param">
            <FdrFlightClass
              className="flight-param-wrapper"
              customLabel={
                withCustomLabels ? (
                  <CustomLabel>{t('forms:flightClassForm.label')}</CustomLabel>
                ) : null
              }
            />
          </div>
        )}
        {!mobile && (
          <FdrFlightParamSubmitWrapper>
            <FdrFlightParamSubmitButton
              type="submit"
              disabled={!isSubmitAllowed}
            >
              {t('common:search')}
            </FdrFlightParamSubmitButton>
          </FdrFlightParamSubmitWrapper>
        )}
      </FdrFlightParamsWrapper>
      {mobile && (
        <FdrFlightFormFooterWrapper>
          <FdrFlightFormFooterOptions>
            <FdrFlightFormFooterToggleOptionsButtonWrapper
              data-id="options-btn"
              variant={E_BUTTON_TYPE.LINK_BUTTON}
              onClick={e => {
                e.preventDefault();
                toggleOptions();
              }}
              isOptionsOpen={isOptionsOpen}
            >
              <span className="flight-options-button-text">
                {t('forms:flightOptions.button')}
              </span>
              <BsIcon
                className="flight-options-button-icon"
                name="chevron-down"
              />
            </FdrFlightFormFooterToggleOptionsButtonWrapper>
          </FdrFlightFormFooterOptions>
          {isOptionsOpen && (
            <FdrFlightClassFieldWrapper>
              <FdrFlightClass
                className="flight-class-field-options"
                customLabel={
                  withCustomLabels ? (
                    <CustomLabel>
                      {t('forms:flightClassForm.label')}
                    </CustomLabel>
                  ) : null
                }
              />
            </FdrFlightClassFieldWrapper>
          )}
          <FdrSubmitButton
            data-id="searchButton"
            type="submit"
            disabled={!isSubmitAllowed}
          >
            {t('common:search')}
          </FdrSubmitButton>
          {handleCancel && (
            <FdrCloseButton
              variant={E_BUTTON_TYPE.LINK_BUTTON}
              type="button"
              className="cancel"
              data-id="close-btn"
              onClick={handleCancel}
            >
              {t('common:cancel')}
            </FdrCloseButton>
          )}
        </FdrFlightFormFooterWrapper>
      )}
    </FdrFlightFormLayoutWrapper>
  );
}
