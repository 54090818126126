import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import { FeatureList } from 'config/pageConfig.constants';
import {
  EFdrFlightTravelType,
  T_Custom_FdrTravelTypeUnit,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';

const DESTINATIONS_SEPARATOR = ',';

const TRAVEL_TYPE_TO_FEATURE_TOGGLE_NAME_MAP = new Map<
  T_Custom_FdrTravelTypeUnit,
  string
>([
  [
    FdrProductTravelType.Package,
    FeatureList.TOP_DESTINATIONS_AUTOSUGGEST_PACKAGE,
  ],
  [
    FdrProductTravelType.HotelOnly,
    FeatureList.TOP_DESTINATIONS_AUTOSUGGEST_HOTEL,
  ],
  [EFdrFlightTravelType.Flight, null], // @tocheck do we need?
]);

const useFdrTopDestinationAutocompleteIds = (
  travelType: U_Extended_TravelType
) => {
  const { getVariant } = useFeatureToggle();
  const featureToggleName = TRAVEL_TYPE_TO_FEATURE_TOGGLE_NAME_MAP.get(
    travelType.fdr
  );

  if (!featureToggleName) {
    return [];
  }

  const topDestinationsVariant = getVariant(featureToggleName);

  if (
    !topDestinationsVariant?.enabled ||
    typeof topDestinationsVariant?.payload?.type !== 'string'
  ) {
    return [];
  }

  return (topDestinationsVariant.payload.value as string)
    .split(DESTINATIONS_SEPARATOR)
    .map(item => {
      return item.trim();
    });
};

export default useFdrTopDestinationAutocompleteIds;
