import { useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import {
  frlSearchTimestampVar,
  frlSearchRequestTimestampVar,
} from 'config/apolloLocalCache';

export function useFdrFlightSearchTimestamps() {
  const { query } = useRouter();
  const hasSearchQueryParam = Boolean('s' in query && query['s']);

  const setFlightSearchRequestTimestamp = (): void => {
    frlSearchRequestTimestampVar(Date.now().toString());
  };

  const setFlightSearchTimestamp = (): void => {
    frlSearchTimestampVar(Date.now().toString());
  };

  const frlSearchTimestamp = useReactiveVar(frlSearchTimestampVar);
  const frlSearchRequestTimestamp = useReactiveVar(
    frlSearchRequestTimestampVar
  );

  const canSearch = useMemo(() => {
    // NOTE: Flight search offer request can be performed only if it's directly requested from the Search Control.
    // Or if there is search query param initiate search
    if (!frlSearchRequestTimestamp) return hasSearchQueryParam;

    if (frlSearchRequestTimestamp && frlSearchTimestamp)
      return (
        new Date(+frlSearchRequestTimestamp) > new Date(+frlSearchTimestamp)
      );

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frlSearchRequestTimestamp, frlSearchTimestamp]);

  return {
    setFlightSearchRequestTimestamp,
    setFlightSearchTimestamp,
    canSearch,
  };
}
